import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import { useNavigate, useParams } from "react-router-dom";
import CryptoJS from "crypto-js";

import {
  addInstructor,
  editInstructor,
  getAllUniversities,
  getInstructor,
} from "../../api";
import { InstructorDetailsSchema } from "../../validations/addInstructor";
import { InstructorUpdateDetailsSchema } from "../../validations/addInstructor";

import { AddInstructorPayload, DepartmentInterface } from "../../api/interface";
import { UniversityInterface } from "../../api/interface";
import { MESSAGES } from "../../../../utils/messages";
import styles from "../../styles/instructor.module.css";
import commonstyles from "../../styles/common.module.css";
import { INSTRUCTOR } from "../../../../utils/instructor";
import { YUP } from "../../../../utils/validations";
import { APP_ROUTES } from "../../../../utils/routes";
import Layout from "../../../../components/Layout";
import AddImage from "./UploadImageModal";
import { uploadImage } from "../../../common/utils";
import {
  faCross,
  faEye,
  faEyeSlash,
  faRemove,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IMAGE_BASE_URL } from "../../../../config";
import { Avatar } from "../../../../assets/images";

const AddInstructor = () => {
  const [universities, setUniversities] = useState<UniversityInterface[]>([]);
  const [department, setDepartment] = useState<DepartmentInterface[]>();
  const [selectedDepartments, setSelectedDepartments] = useState<number[]>([]);
  const [croppedImage, setCroppedImage] = useState<File | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [imageFile, setImageFile] = useState<string | null>(null);
  const [disableButton, setDisableButton] = useState<boolean>(false);

  const { id } = useParams();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState<boolean>(false);
  const removeUniversity = (index: any) => {
    const na = instructorDetailsFormik.values.universities;
    const keys = Object.keys(na);
    const keyToRemove = keys[index];
    //@ts-ignore
    delete na[keyToRemove];
    instructorDetailsFormik.setFieldValue("universities", na);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const transformData = (
    data: Record<string, any>
  ): Record<string, number[]> => {
    return Object.entries(data).reduce((acc, [key, value]) => {
      if (value.id && value.dep) {
        acc[value.id] = value.dep.map((dep: string) => parseInt(dep, 10));
      }
      return acc;
    }, {} as Record<string, number[]>);
  };

  const createFormData = (
    values: any,
    mobileNumber: any,
    croppedImage: any
  ): FormData => {
    const formData = new FormData();
    formData.append("first_name", values.firstName);
    formData.append("last_name", values.lastName);
    formData.append("country_code", mobileNumber?.countryCallingCode || "");
    formData.append("mobile", mobileNumber?.nationalNumber || "");
    formData.append("email", values.email);
    formData.append("instructor_role", values.role);
    formData.append("password", values.password);
    formData.append(
      "universities",
      JSON.stringify(transformData(values.universities))
    );
    formData.append(
      "encrypted_password",
      CryptoJS.AES.encrypt(
        values.password,
        process.env.REACT_APP_PASSWORD_KEY as string
      ).toString()
    );

    if (croppedImage) {
      formData.append("avatar", croppedImage);
    }

    return formData;
  };

  const instructorDetailsFormik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      university: 0,
      department: [],
      email: "",
      phoneNumber: "",
      password: "",
      role: "",
      avatar: undefined,
      universities: { "0": { id: "0", dep: [], allDep: [] } },
    },
    enableReinitialize: true,
    validationSchema: InstructorDetailsSchema,
    onSubmit: (values) => {
      const mobileNumber = parsePhoneNumber(values.phoneNumber);

      const formData = createFormData(values, mobileNumber, croppedImage);

      const apiCall = !id
        ? addInstructor(formData)
        : editInstructor(Number(id), formData);

      const messages = {
        pending: !id ? MESSAGES.INSTRUCTOR.ADD : MESSAGES.INSTRUCTOR.EDIT,
        success: !id ? MESSAGES.INSTRUCTOR.SUCCESS : MESSAGES.INSTRUCTOR.EDITED,
        error: !id
          ? MESSAGES.INSTRUCTOR.INVALID
          : MESSAGES.INSTRUCTOR.NOTEDITED,
      };

      toast.promise(apiCall, {
        pending: messages.pending,
        success: {
          render() {
            setDisableButton(true);
            setCroppedImage(null);
            setImageFile(null);
            instructorDetailsFormik.resetForm();
            navigate(APP_ROUTES.INSTRUCTORS_LIST);
            return messages.success;
          },
        },
        error: {
          render({ data }: { data: { data: { message: string } } }) {
            setDisableButton(false);
            return data?.data?.message;
          },
        },
      });
    },
  });

  const addMoreUniversity = () => {
    const cl = Object.keys(instructorDetailsFormik.values.universities).length;
    const no = {
      ...instructorDetailsFormik.values.universities,
      [cl]: { id: 0, dep: [] },
    };
    instructorDetailsFormik.setFieldValue("universities", no);
  };

  const handleUniversitiesChange = (e: any, key: any): any => {
    setSelectedDepartments([]);
    const universiti = instructorDetailsFormik.values.universities;
    const deps = addDepartments(Number(e.target.value));
    const no = {
      ...universiti,
      [key]: { id: e.target.value, dep: [], allDep: deps },
    };
    instructorDetailsFormik.setFieldValue("universities", no);
  };

  const addDepartments = (id: Number) => {
    const selectedUniversity = universities.filter(
      (uni: UniversityInterface) => {
        return Number(uni.id) === Number(id);
      }
    );
    setDepartment(selectedUniversity[0]?.universityDepartments);
    return selectedUniversity[0]?.universityDepartments;
  };

  const handleAddInstructor = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(instructorDetailsFormik.values);
    instructorDetailsFormik.submitForm();
  };

  useEffect(() => {
    getAllUniversities().then((res) => {
      setUniversities(res?.data);
    });
  }, []);

  useEffect(() => {
    if (croppedImage) {
      setImageFile(URL.createObjectURL(croppedImage));
    }
  }, [croppedImage]);

	useEffect(() => {
		const fetchInstructorDetails = async () => {
			if (!id) {
				instructorDetailsFormik.resetForm();
				setImageFile(null);
				setDepartment([]);
				setSelectedDepartments([]);
				return;
			}

			try {
				const res = await getInstructor({ id: Number(id), for_detail: true });
				const {
					data: {
						country_code,
						mobile,
						instructorUniversities,
						first_name,
						last_name,
						email,
						instructorDepartments,
						encrypted_password,
						instructor_role,
						avatar,
					},
				} = res;

				populateFormikFields({
					first_name,
					last_name,
					avatar,
					instructor_role,
					country_code,
					mobile,
					encrypted_password,
				});

				populateUniversityDetails(instructorUniversities);

				const departmentList = instructorDepartments.map(
					(dep: any) => dep.department_id
				);
				if (departmentList.length) {
					setSelectedDepartments(departmentList);
				}
			} catch (error) {
				console.error("Error fetching instructor details:", error);
			}
		};

		const populateFormikFields = ({
			first_name,
			last_name,
			avatar,
			instructor_role,
			country_code,
			mobile,
			encrypted_password,
		}: any) => {
			instructorDetailsFormik.setFieldValue(
				INSTRUCTOR.FORMIK.FIRSTNAME,
				first_name
			);
			instructorDetailsFormik.setFieldValue(
				INSTRUCTOR.FORMIK.LASTNAME,
				last_name
			);

			if (avatar) {
				const fileURL = `${IMAGE_BASE_URL}${avatar}`;
				setImageFile(fileURL);
			}

			instructorDetailsFormik.setFieldValue("role", instructor_role);
			instructorDetailsFormik.setFieldValue(
				INSTRUCTOR.FORMIK.PHONENUMBER,
				`${country_code && "+"}${country_code}${mobile}`
			);

			if (encrypted_password) {
				const decryptedPassword = CryptoJS.AES.decrypt(
					encrypted_password as string,
					process.env.REACT_APP_PASSWORD_KEY as string
				).toString(CryptoJS.enc.Utf8);
				instructorDetailsFormik.setFieldValue("password", decryptedPassword);
			}
		};

		const populateUniversityDetails = (universities: any[]) => {
			if (!universities.length) return;

			addDepartments(universities[0].university_id);

			const universityData = universities.reduce((acc: any, uni: any) => {
				const departments = uni.departments.map((dep: any) =>
					dep.department_id.toString()
				);
				acc[uni.university_id] = {
					id: uni.university_id,
					dep: departments,
					allDep: addDepartments(uni.university_id),
				};
				return acc;
			}, {});

			instructorDetailsFormik.setFieldValue("universities", universityData);
		};

		fetchInstructorDetails();
	}, [id, universities]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleCheckboxClick = (
    e: React.ChangeEvent<HTMLInputElement>,
    key: any
  ) => {
    //@ts-ignore
    const cv = instructorDetailsFormik.values.universities[key].dep;
    //@ts-ignore
    const cvo = instructorDetailsFormik.values.universities[key];
    if (e.target.checked === true) {
      //@ts-ignore
      instructorDetailsFormik.setFieldValue("universities", {
        ...instructorDetailsFormik.values.universities,
        [key]: { ...cvo, dep: [e.target.value, ...cv] },
      });
    } else {
      const selectedDepartmentsCopy = cv;
      const selectedDepartmentsList = selectedDepartmentsCopy.filter(
        (num: any) => {
          return Number(num) !== Number(e.target.value);
        }
      );
      //@ts-ignore
      instructorDetailsFormik.setFieldValue("universities", {
        ...instructorDetailsFormik.values.universities,
        [key]: { ...cvo, dep: selectedDepartmentsList },
      });
    }
  };

  return (
    <Layout>
      <div className="container mt-5">
        <div className="card shadow-lg p-4">
          <h2 className="text-center mb-4">
            {id ? "Edit Instructor" : "Add New Instructor"}
          </h2>

          <Form onSubmit={handleAddInstructor}>
            <div className="row mb-3">
              {/* First Name */}
              <div className="col-md-6">
                <Form.Group controlId="formFirstName">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter First Name"
                    value={instructorDetailsFormik.values.firstName}
                    name="firstName"
                    onChange={instructorDetailsFormik.handleChange}
                  />
                  {instructorDetailsFormik.touched.firstName &&
                    instructorDetailsFormik.errors.firstName && (
                      <div className="text-danger mt-1">
                        {instructorDetailsFormik.errors.firstName}
                      </div>
                    )}
                </Form.Group>
              </div>

              {/* Last Name */}
              <div className="col-md-6">
                <Form.Group controlId="formLastName">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Last Name"
                    value={instructorDetailsFormik.values.lastName}
                    name="lastName"
                    onChange={instructorDetailsFormik.handleChange}
                  />
                  {instructorDetailsFormik.touched.lastName &&
                    instructorDetailsFormik.errors.lastName && (
                      <div className="text-danger mt-1">
                        {instructorDetailsFormik.errors.lastName}
                      </div>
                    )}
                </Form.Group>
              </div>
            </div>

            <div className="row mb-3">
              {/* Role */}
              <div className="col-md-6">
                <Form.Group controlId="formRole">
                  <Form.Label>Role</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Role"
                    value={instructorDetailsFormik.values.role}
                    name="role"
                    onChange={instructorDetailsFormik.handleChange}
                  />
                  {instructorDetailsFormik.touched.role &&
                    instructorDetailsFormik.errors.role && (
                      <div className="text-danger mt-1">
                        {instructorDetailsFormik.errors.role}
                      </div>
                    )}
                </Form.Group>
              </div>

              {/* Phone Number */}
              <div className="col-md-6">
                <Form.Group controlId="formPhoneNumber">
                  <Form.Label>Phone Number</Form.Label>
                  <PhoneInput
                    international
                    placeholder="Enter phone number"
                    value={instructorDetailsFormik.values.phoneNumber}
                    name="phoneNumber"
                    onChange={(value) =>
                      instructorDetailsFormik.setFieldValue(
                        INSTRUCTOR.FORMIK.PHONENUMBER,
                        value
                      )
                    }
                  />
                  {instructorDetailsFormik.touched.phoneNumber &&
                    instructorDetailsFormik.errors.phoneNumber && (
                      <div className="text-danger mt-1">
                        {instructorDetailsFormik.errors.phoneNumber}
                      </div>
                    )}
                </Form.Group>
              </div>
            </div>

            <div className="row mb-4">
              {/* Password */}
              <div className="col-md-6">
                <Form.Group controlId="formPassword">
                  <Form.Label>Password</Form.Label>
                  <div className="position-relative">
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter Password"
                      value={instructorDetailsFormik.values.password}
                      name="password"
                      onChange={instructorDetailsFormik.handleChange}
                    />
                    <FontAwesomeIcon
                      icon={showPassword ? faEyeSlash : faEye}
                      onClick={togglePasswordVisibility}
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "10px",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                  {instructorDetailsFormik.touched.password &&
                    instructorDetailsFormik.errors.password && (
                      <div className="text-danger mt-1">
                        {instructorDetailsFormik.errors.password}
                      </div>
                    )}
                </Form.Group>
              </div>

              {/* Profile Image */}
              <div className="col-md-6 text-center">
                <Form.Label>Profile Image</Form.Label>
                <div className="d-flex flex-column align-items-center">
                  <Button
                    variant="primary"
                    onClick={() => setShowModal(true)}
                    className=" app-primary-btn mb-3"
                  >
                    {croppedImage
                      ? "Update Profile Image"
                      : "Upload Profile Image"}
                  </Button>
                  {imageFile && (
                    <img
                    src={!imageFile || imageFile.endsWith("/images/user.png") ? Avatar : imageFile}
                      alt="Profile Preview"
                      className="rounded-circle shadow"
                      style={{ width: "100px", height: "100px" }}
                    />
                  )}
                </div>
              </div>
            </div>

            <hr />

            <div className="mb-3">
              <Button onClick={addMoreUniversity} className="app-primary-btn mb-3">
                Add More University
              </Button>
            </div>

            <div>
              {Object.entries(instructorDetailsFormik.values.universities).map(
                ([key, value], index) => (
                  <div key={index} className="mb-4">
                    {/* University */}
                    <Form.Group controlId={`formUniversity${index}`}>
                      <Form.Label className="d-flex justify-content-between align-items-center">
                        University {index + 1}
                        {index !== 0 && (
                          <FontAwesomeIcon
                            icon={faRemove}
                            style={{ cursor: "pointer" }}
                            onClick={() => removeUniversity(index)}
                          />
                        )}
                      </Form.Label>
                      <Form.Control
                        as="select"
                        value={value.id}
                        onChange={(e) => handleUniversitiesChange(e, key)}
                      >
                        <option value="0">Select University</option>
                        {universities.map((university) => (
                          <option key={university.id} value={university.id}>
                            {university.name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>

                    {/* Departments */}
                    {value.id !== "0" && (
                      <div className="mt-3">
                        <Form.Label>Departments</Form.Label>
                        <div className="d-flex flex-wrap">
                          {value.allDep?.map((dep: DepartmentInterface) => (
                            <div
                              key={dep.id.toString()}
                              className="form-check me-3 mb-2"
                              style={{ minWidth: "200px" }}
                            >
                              <input
                                type="checkbox"
                                className="form-check-input"
                                value={dep.id.toString()}
                                checked={
                                  //@ts-ignore
                                  instructorDetailsFormik.values.universities[
                                    key
                                  ].dep.includes(dep.id.toString())
                                    ? true
                                    : false
                                }
                                onChange={(e) => handleCheckboxClick(e, key)}
                              />
                              <label className="form-check-label">
                                {dep.name}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                )
              )}
            </div>
          <Button
            type="submit"
            className="app-primary-btn btn btn-primary w-100"
            disabled={instructorDetailsFormik.isSubmitting && disableButton}
          >
            {id ? INSTRUCTOR.EDIT : INSTRUCTOR.ADD}
          </Button>
          </Form>
        </div>

        <AddImage
          croppedImage={croppedImage}
          setCroppedImage={setCroppedImage}
          showModal={showModal}
          handleClose={handleClose}
        />
      </div>
    </Layout>
  );
};

export default AddInstructor;
