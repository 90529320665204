import React, { useEffect, useState } from "react";
import Layout from "../../../../components/Layout";
import moment from "moment";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { Dropdown, Spinner } from "react-bootstrap";
import { courseStatus, getPurchasedCourses, getStudent } from "../../api";
import { PurchasedChapterItem, PurchasedCourseItem, Student,} from "../../api/interface";
import { STUDENT } from "../../../../utils/student";
import { Avatar } from "../../../../assets/images";
import { PaginationInterface } from "../../../../components/Pagination/interfaces";
import { MESSAGES } from "../../../../utils/messages";
import ProfileStatus from "../../modals/ProfileStatus";
import detailstyle from "../../styles/detail.module.css";
import AddCourse from "../../modals/AddCourse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH, faMoneyBill } from "@fortawesome/free-solid-svg-icons";
import commonStyles from "../../../common/styles/common.module.css";
import { ROUTE_CONSTANTS } from "../../../../routes/constants";
import { IMAGE_BASE_URL } from "../../../../config";
import ConfirmationModal from "../../../../components/common/ConfirmationModal";
import { removeAssignedCourse } from "../../../university/api/index";

const StudentDetails = () => {
const { id } = useParams();
const navigate = useNavigate();
const [studentInfo, setStudentInfo] = useState<Student>();
const [pagination, setPagination] = useState<PaginationInterface>({
	page: 1, limit: 10000, count: 0, totalPages: 0,
});
const [userId, setUserId] = useState<number>(0);
const [courseList, setCourseList] = useState<PurchasedCourseItem[]>([]);
const [showProfileModal, setShowProfileModal] = useState<boolean>(false);
const [showCourseModal, setShowCourseModal] = useState<boolean>(false);
const [status, setStatus] = useState<string>("");
const [studetStatus, settudetStatus] = useState<string>("");
const [chapterList, setChapterList] = useState<PurchasedChapterItem[]>([]);
const [loading, setLoading] = useState<boolean>(true);
const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
const [courseToDelete, setCourseToDelete] = useState<number | null>(null);
const [courseToDeleteOrderID, setcourseToDeleteOrderID] = useState<number | null>(null);
const openDeleteModal = (CourseID: number, orderID: number) => {
	setCourseToDelete(CourseID);
	setcourseToDeleteOrderID(orderID);
	setShowDeleteModal(true);
};
const confirmDelete = () => {
	if (courseToDelete !== null) {
		if (courseToDeleteOrderID !== null) {
			toast.promise(
				removeAssignedCourse(userId, courseToDeleteOrderID, courseToDelete),
				{
					pending: MESSAGES.COURSE.REMOVING,
					success: {
						render() {
							window.location.reload();
							return MESSAGES.COURSE.REMOVED;
						},
					},
					error: MESSAGES.COURSE.NOTREMOVED,
				}
			);
		}
	}
	setShowDeleteModal(false);
	setCourseToDelete(null);
};

const handleCourseStatus = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number
) => {
	const newStatus = e.target.checked === true ? 1 : 2;
	toast.promise(courseStatus(Number(id), newStatus), {
		pending: {
			render() {
			return MESSAGES.COURSE.COURSESTATUS;
			},
		},
		success: {
			render({}) {
			if (newStatus === 1) {
				e.target.checked = true;
			} else {
				e.target.checked = false;
			}
			return MESSAGES.COURSE.COURSESTATUSCHANGED;
			},
		},
		error: {
			render() {
			if (newStatus === 1) {
				e.target.checked = false;
			} else {
				e.target.checked = true;
			}

			return MESSAGES.COURSE.NOTCOURSESTATUS;
			},
		},
	});
};
useEffect(() => {
	setLoading(true);
	getPurchasedCourses(pagination, Number(id)).then((res) => {
		const purchaseCourses: PurchasedCourseItem[] = [];
		const purchasedChapters: PurchasedChapterItem[] = [];
		setUserId(res.data[0]?.user_id);
		res.data.forEach((purchasedCourse) => {
			if (purchasedCourse.purchased_courses?.length > 0) {
				purchasedCourse.purchased_courses.forEach((course) => {
					course.order_number = purchasedCourse.order_number;
					course.order_price = purchasedCourse.order_price;
					course.order_type = purchasedCourse.order_type;
					course.payment_type = purchasedCourse.payment_type;
					course.order_id = purchasedCourse.id;
					purchaseCourses.push(course);
				});
			}
			if (purchasedCourse.purchased_chapters?.length > 0) {
				purchasedCourse.purchased_chapters.forEach((chapter) => {
					chapter.order_number = purchasedCourse.order_number;
					purchasedChapters.push(chapter);
				});
			}
		});

		setCourseList(purchaseCourses);
		setChapterList(purchasedChapters);
		setLoading(false);
	});
}, [showCourseModal]);
useEffect(() => {
    getStudent({ id: Number(id) }).then((res) => {
		const { user } = res;
		setStudentInfo(user);
		setStatus(user.status === 1 ? STUDENT.DEACTIVATE : STUDENT.ACTIVATE);
		settudetStatus(user.status === 2 ? "Deactive" : "Active");
    });
}, [showProfileModal]);

const handleProfileClose = () => {
	setShowProfileModal(false);
};
const handleCourseClose = () => {
	setShowCourseModal(false);
};

return (
    <Layout>
      <div className={detailstyle.uttrePad}>
        <div className={detailstyle.heaingFilter}>
          <h3>Student Management / Details</h3>
        </div>
        <div className={detailstyle.tableData}>
          	<div className={detailstyle.addBtn}>
				<button onClick={() => setShowCourseModal(true)}>Add Course</button>
				<button onClick={() => setShowProfileModal(true)} 
					className={studentInfo?.status === 1 ? detailstyle.deactiveProfile : detailstyle.activeProfile}>
					{studentInfo?.status === 1 ? STUDENT.DEACTIVATE : STUDENT.ACTIVATE}
				</button>
			</div>
			<div className="row">
				<div className="col-md-3">
					<div className={detailstyle.leftProfile}>
						<div className={detailstyle.proImage}>
							<img
								src={
								!studentInfo?.avatar ||
								studentInfo?.avatar.endsWith("/images/user.png")
									? Avatar
									: `${IMAGE_BASE_URL}${studentInfo?.avatar}`
								}
								width={50}
								height={50}
								alt=""
							/>
						</div>
						<h4>{studentInfo?.first_name}</h4>
						<h5>Student</h5>
						<div className={detailstyle.stars}>
							<div className={detailstyle.outStar}>
								<span className="fa fa-star checked"></span>
								<span className="fa fa-star checked"></span>
								<span className="fa fa-star checked"></span>
								<span className="fa fa-star"></span>
								<span className="fa fa-star"></span>
							</div>
							{/* <label>4.5</label> */}
						</div>
						<h6>
							Status :{" "}
							<span>
								{" "}
								<i className="fas fa-check-circle"></i>{" "}
								{/* {studentInfo?.student?.status} */}
								{studetStatus}
							</span>
						</h6>
					</div>
				</div>
				<div className="col-md-9">
					<div className={detailstyle.rightProfile}>
						<h4>About</h4>
						<p>{studentInfo?.about}</p>
						<h4>{studentInfo?.student.student_type}</h4>
						{studentInfo?.student.student_type === STUDENT.UNIVERSITY && (
							<p>{studentInfo.student?.university?.name}</p>
						)}
						<h4>Department</h4>
						{studentInfo?.student.student_type === STUDENT.UNIVERSITY && (
							<p>{studentInfo.student?.universityDepartment?.name}</p>
						)}
						{studentInfo?.student.student_type === STUDENT.SCHOOL && (
							<p>{studentInfo.student?.school?.name}</p>
						)}
						{studentInfo?.student.student_type === STUDENT.GENERAL && (
							<p>{studentInfo?.student?.training_field}</p>
						)}
					</div>
				</div>
			</div>
        </div>
        {!loading ? (
			<div className={detailstyle.outTabl}>
				<div className={detailstyle.purchaseCorse}>
				<h3>Purchased Courses</h3>
					<table>
						<tr>
							<th>Order ID</th>
							<th>Instructor</th>
							<th>Course Name</th>
							<th>Subscription Type</th>
							<th>Price</th>
							<th>Order Type</th>
							<th>Payment Type</th>
							<th>Date</th>
							<th>Change Status</th>
							<th>Action</th>
						</tr>
						{courseList?.map((course) => {
							return (
								<tr key={course.pivot?.id}>
									<td className="text-center">{course?.order_number}</td>
									<td>
										<img width={"50px"} src={course.instructor?.avatar ? course.instructor?.avatar : Avatar} alt="" />{" "}
										{/* <img width={"50px"} src={`${IMAGE_BASE_URL}${course.instructor?.avatar ? course.instructor?.avatar : Avatar}`} alt="" />{" "} */}
										<span
											onClick={() => navigate(`/instructors/${course.instructor?.id}`)}
											style={{ cursor: "pointer", color: "#102cd3", textDecoration: "underline" }}
										>
											{course.instructor?.first_name}
										</span>
									</td>
									<td className="text-center">{course?.name}</td>
									<td className="text-center"> {course?.pivot?.subcription_type === 1 ? "Online" : "In-Person"}</td>
									<td className="text-center">{course?.order_price}KD</td>
									<td className="text-center"> {course?.order_type === "Individual" ? "Chapter" : "Course"}</td>
									<td className="text-center">{course?.payment_type}</td>
									<td className="text-center">{moment(course.created_at).format("MMM DD, YYYY")}</td>
									<td className="text-center">
										{" "}
										<label className={detailstyle.switch}>
											<input
												onChange={(e) =>
												handleCourseStatus(e, course.pivot?.id)
												}
												defaultChecked={
												course.pivot?.status === 1 ? true : false
												}
												type="checkbox"
											/>
											<span className={`${detailstyle.slider} ${detailstyle.round}`}></span>
										</label>
									</td>
									<td>
										<Dropdown drop={"down-centered"} className={commonStyles.tddropdown}>
											<Dropdown.Toggle>
												<FontAwesomeIcon icon={faEllipsisH} />
											</Dropdown.Toggle>
											<Dropdown.Menu>
												<Dropdown.Item onClick={() =>navigate(`${ROUTE_CONSTANTS.COURSE_INSTALLMENT}/${userId}/${course?.order_id}/${course?.id}`)}>
												<FontAwesomeIcon className={commonStyles.iconmargin} icon={faMoneyBill} size="xs" />
												View Installments
												</Dropdown.Item>
												<Dropdown.Item onClick={() => openDeleteModal( course?.id as number, course?.order_id as number)}>
												<FontAwesomeIcon className={commonStyles.iconmargin} icon={faMoneyBill} size="xs" />
												Delete
												</Dropdown.Item>
											</Dropdown.Menu>
										</Dropdown>
									</td>
								</tr>
							);
						})}
						{chapterList?.map((chapter) => {
						return (
							<tr key={chapter.pivot?.id}>
								<td className="text-center">{chapter?.order_number}</td>
								<td>
									<img width={"50px"} src={ chapter.course.instructor?.avatar ? chapter.course.instructor?.avatar : Avatar } alt="" />{" "}
									{chapter?.course?.instructor?.first_name}
								</td>
								<td className="text-center">{chapter?.name}</td>
								<td className="text-center">{chapter?.category?.name}</td>
								<td className="text-center">
									{moment(chapter.created_at).format("MMM DD, YYYY")}
								</td>
								<td className="text-center Paymnt">{chapter?.price}KD</td>
								<td className="text-center">
									{" "}
									<label className={detailstyle.switch}>
										<input
											onChange={(e) =>
											handleCourseStatus(e, chapter.pivot?.id)
											}
											defaultChecked={
											chapter.pivot?.status === 1 ? true : false
											}
											type="checkbox"
										/>
										<span className={`${detailstyle.slider} ${detailstyle.round}`}></span>
									</label>
								</td>
								<td>
									<Dropdown drop={"down-centered"} className={commonStyles.tddropdown}>
										<Dropdown.Toggle>
											<FontAwesomeIcon icon={faEllipsisH} />
										</Dropdown.Toggle>

										<Dropdown.Menu>
											<Dropdown.Item
											// onClick={() =>
											// 	navigate(
											// 		`${APP_ROUTES.STUDENTS_LIST}/edit/${item.id}`
											// 	)
											// }
											>
											<FontAwesomeIcon className={commonStyles.iconmargin} icon={faMoneyBill} size="xs"/>
											View Installments
											</Dropdown.Item>
										</Dropdown.Menu>
									</Dropdown>
								</td>
							</tr>
						);
						})}
					</table>
				</div>
			</div>
        ) : (
			<div className="d-flex justify-content-center">
				<Spinner animation="border" role="status"></Spinner>
			</div>
        )}
      </div>
      <ProfileStatus id={studentInfo?.id as number} status={status} showModal={showProfileModal} handleClose={handleProfileClose}/>
      <AddCourse showModal={showCourseModal} handleClose={handleCourseClose} />
      <ConfirmationModal
        show={showDeleteModal}
        title="Remove Assigned Course"
        message="Are you sure you want to remove this assigned course? This action cannot be undone."
        onConfirm={confirmDelete}
        onCancel={() => setShowDeleteModal(false)}
        confirmButtonText="Delete"
        cancelButtonText="Cancel"
      />
    </Layout>
  );
};

export default StudentDetails;
