import React from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { GraphProps } from "./interface";

const ColumnGraph = ({ data, name }: GraphProps) => {
  const series = [
    {
      name: name,
      data: data,
    },
  ];

  const options: ApexOptions = {
    chart: {
      type: "bar",
      height: 350,
      width: 300,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%", // Slightly reduced for better spacing
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December",
      ],
      labels: {
        rotate: -45, // Rotates labels to avoid overlapping
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return value.toFixed(0); // Removes decimals for clarity
        },
      },
      tickAmount: 5, // Reduces excessive grid lines
      title: {
        text: "Count",
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + " Course";
        },
      },
    },
    grid: {
      show: true,
      strokeDashArray: 5, // Light gridlines for better readability
    },
    title: {
      text: name,
      floating: false, // Ensures the title is positioned properly
      offsetY: 10, // Adjusted title position
      style: {
        fontSize: "16px",
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
          },
          xaxis: {
            labels: {
              rotate: -30,
            },
          },
        },
      },
    ],
  };

  return (
    <div>
      <ReactApexChart options={options} series={series} type="bar" height={500} width={600} />
    </div>
  );
};

export default ColumnGraph;
