import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { Dropdown, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEllipsisH,
  faEye,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

import Layout from "../../../../../components/Layout/index";
import commonStyles from "../../../../common/styles/common.module.css";
import Pagination from "../../../../../components/Pagination";
import { PaginationInterface } from "../../../../../components/Pagination/interfaces";
import { INSTRUCTOR } from "../../../../../utils/instructor";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { departmentModal } from "../../../../../redux/reducers/department";
import { MESSAGES } from "../../../../../utils/messages";
 import { CourseDeatails } from "../../../../course/api/interface";
import { courseList } from "../../../../course/api";
import AddOrEdit from "./Modals/AddCourse";
import { IMAGE_BASE_URL } from "../../../../../config";
import { CourseDummy } from "../../../../../assets/images";

const CategoryCourses = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const [course, setCourse] = useState<CourseDeatails[]>([]);
  const [pagination, setPagination] = useState<PaginationInterface>({
    page: 1,
    limit: 10,
    count: 0,
    totalPages: 0,
  });
  const [showModal, setShowModal] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(true);

  const selectedDepartment = useAppSelector((state) => state.departmentReducer);
  const refresh = () => {
    setLoading(true);
    const query = {
      per_page: pagination.limit,
      page: pagination.page,
    };

    courseList(query, undefined,undefined,undefined,undefined,undefined,undefined,undefined,Number(id)).then((res) => {
      const {
        paginate: { total, last_page },
        data,
      } = res;
      setPagination({ ...pagination, count: total, totalPages: last_page });
      setCourse(data);
      console.log(data);
      setLoading(false);
    });
  };
  useEffect(() => {
    refresh();
  }, []);
  useEffect(() => {
    refresh();
  }, [pagination.page]);

  const handleClose = () => {
    setShowModal(false);
    if (selectedDepartment.editOrAdd === INSTRUCTOR.REDUX.ADD) {
      if (pagination.page === 1) {
        refresh();
      } else {
        setPagination({ page: 1, limit: 10, count: 0, totalPages: 0 });
      }
    } else {
      refresh();
    }
  };

  return (
    <Layout>
      <div className={commonStyles["outtre-pad"]}>
        <>
          <span className="instr_manager d-flex justify-content-between align-items-center">
            <h3>University Management / Categories /Courses</h3>

            <div className={commonStyles["heaing-filter"]}>
            </div>
          </span>
        </>
        <div className={commonStyles["heaing-filter"]}>
          <h3></h3>
        </div>
        {!loading ? (
          <div className={commonStyles["table-data"]}>
            <div className={`${commonStyles["property-head"]} pb-1`}>
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">

                </li>
              </ul>
              <div className={commonStyles["filter-pro"]}>
                <button
									onClick={() => {
										setShowModal(true);
									}}
									className={commonStyles["add-lead"]}
								>
									<FontAwesomeIcon icon={faPlus} /> Add Course in Category
								</button>
              </div>
            </div>
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <div>
                  <table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Course Mode</th>
                        <th>code</th>
                        <th>Instructor</th>
                        <th>Department count</th>
                        <th>Student Count</th>
                        <th>Online Price</th>
                        <th>In-Person Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {course.map((item) => (
                        <tr key={item.id}>
                          <td className="bg-light">
                          <img
                                src={!item.image || item.image.endsWith("/storage/") ? CourseDummy : `${IMAGE_BASE_URL}${item.image}`}
                                height={40}
                                width={40}
                                alt="avatar"
                              />{item.name}</td>
                          <td className="text-center">
                            {item?.course_type_name}
                          </td>
                          <td className="bg-light">{item.code}</td>

                          <td >{item.instructor?.full_name}</td>

                          <td className="bg-light">{item.departments.length}</td>
                          <td  >{item.purchased_students_count}</td>
                          <td className="text-center bg-light ">
                            {item?.course_type !== 2 ? (
                            <>
                              {item?.online_price === null ? (
                              "Not Set"
                              ) : (
                              <>
                                {parseInt(item?.online_price?.toString(), 10) !== parseInt(item?.online_discounted_price?.toString(), 10) ? (
                                <s style={{ marginRight: "5px" }}>{parseInt(item?.online_price?.toString(), 10)}</s>
                                ) : null}
                                {parseInt(item?.online_discounted_price?.toString(), 10)} KD
                              </>
                              )}
                            </>
                            ) : (
                               "Not Applicable"
                            )}
                            </td>
                            <td className="text-center">
                              {item?.course_type !== 1 ? (
                            <>
                              {item?.in_person_price === null ? (
                              "Not Set"
                              ) : (
                              <>
                              {parseInt(item?.in_person_price?.toString(), 10) !== parseInt(item?.in_person_discounted_price?.toString(), 10) ? (
                              <s style={{ marginRight: "5px" }}>{parseInt(item?.in_person_price?.toString(), 10)}</s>
                              ) : null}
                              {parseInt(item?.in_person_discounted_price?.toString(), 10)} KD
                              </>
                              )}
                            </>
                            ) : (
                               "Not Applicable"
                            )}
                            </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" role="status"></Spinner>
          </div>
        )}
      </div>

      <Pagination
        paginationState={pagination}
        setPaginationState={setPagination}
      />
      <AddOrEdit showModal={showModal} handleClose={handleClose} />
    </Layout>
  );
};

export default CategoryCourses;
