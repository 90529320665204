import * as Yup from "yup";
import { YUP } from "../../../utils/validations";
import { COUPONS } from "../../../utils/coupons";

const segmentSchema = Yup.object().shape({

	min: Yup.number()
	  .required('Min is required')
	  .min(0, 'Min should be greater than or equal to 0'),
	max: Yup.number()
	  .required('Max is required')
	  .min(Yup.ref('min'), 'Max should be greater than or equal to Min'),

	  discount: Yup.number()
	  .required('Min is required')
	  .min(0, 'Min should be greater than or equal to 0'),
  });

export const CouponDetailsSchema = Yup.object().shape({
	couponType:Yup.number().required(YUP.REQUIRED),
	code: Yup.mixed()
	.when(COUPONS.FORMIK.COUPON_TYPE, {
		is: (value: any) => value === 6 || value === '6',
		then: () => Yup.string().required("Required"),


	}),
	//@ts-ignore
	segments: Yup.lazy((value,{parent}) => {
		// Validate the segments object with dynamic keys
		if(parent.couponType==1||parent.couponType=="1")
		{return Yup.object()

    .shape(
      Object.keys(value || {}).reduce((acc:any, key) => {
        acc[key] = Yup.array().of(
          Yup.object().shape({
            min: Yup.number()
              .required('Minimum value is required')
              .min(0, 'Minimum value must be greater than or equal to 0'),
            max: Yup.number()
              .required('Maximum value is required')
              .min(Yup.ref('min'), 'Maximum value must be greater than or equal to Minimum value'),
            discount: Yup.number()
              .required('Discount is required')
              .min(0, 'Discount must be at least 0'),
          })
        );
        return acc;
      }, {})
    ) .test('has-keys', 'At least one University is required', (obj) => {
		return obj && Object.keys(obj).length > 0;
	});}
	return Yup.object().nullable(); // or return Yup.object().notRequired();
	  }),

	discount_amount:Yup
		.number()
		.when(COUPONS.FORMIK.COUPON_TYPE, {
			is: 6,
			then: () => Yup.number().min(1, YUP.GREATER0)
				.required(YUP.REQUIRED)


		}),
	// selectedUniversities: Yup.array().of(
	// 	Yup.object().shape({

	// 		  discount:Yup
	// 		  .number().min(1, YUP.GREATER0).required(YUP.REQUIRED)
	// 		  .when(COUPONS.FORMIK.DISCOUNT_TYPE, {
	// 			  is: "2",
	// 			  then: () => Yup.number()
	// 				  .required(YUP.REQUIRED)
	// 				  .min(1, YUP.GREATER0)
	// 				  .max(99, YUP.LESS100)

	// 		  }),
	// 		//   discount: Yup.number().min(1, 'Discount must be greater than 0').required('Discount is required'),
	// 	})
	// 	  ).min(1, 'At least one university must be selected'),



	date: Yup.date().required('Expiry date is required'),
	text:Yup
		.string()
		.when(COUPONS.FORMIK.COUPON_TYPE, {
			is: 5,
			then: () => Yup.string()
				.required(YUP.REQUIRED)


		}),
	limit:Yup.mixed()
		.when(COUPONS.FORMIK.COUPON_TYPE, {
			is: (value: number) => value === 2 || value === 5,
			then: () => Yup.number().min(1, YUP.GREATER0).required(YUP.REQUIRED),


		}),
		slimit:Yup.mixed()
		.when(COUPONS.FORMIK.COUPON_TYPE, {
			is: (value: any) => value === 6 || value === '6',
			then: () => Yup.number().min(1, YUP.GREATER0),


		}),
	selectedCourse:Yup
		.number()
		.when(COUPONS.FORMIK.COUPON_TYPE, {
			is: (value: number) => value === 4,
			then: () => Yup.number().min(1, YUP.GREATER0)
				.required(YUP.REQUIRED)


		}),
		selectedUniversities: Yup.array().of(
			Yup.object().shape({
				university_id: Yup.number().required('Required').notOneOf([0], 'Required'),
				discount: Yup.number().required('Required').min(1,"Required"),
				courses: Yup.array().of(Yup.string()).min(1, 'At least one course is required').required('Required'),
			})
		).when('couponType', {
			is: (couponType:any) => couponType === 2 || couponType === 3 ||couponType === "2" || couponType === "3",
			then: (schema) => schema.required('Universities required') .min(1, 'Required'),
			otherwise: (schema) => schema.notRequired(),
		}),

})

