import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { Dropdown, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
// import { toast } from "react-toastify";

import { courseList } from "../../../../course/api";
import { CourseDeatails } from "../api/interface";
import Layout from "../../../../../components/Layout";
import commonStyles from "../../../../common/styles/common.module.css";
import Pagination from "../../../../../components/Pagination";
import { PaginationInterface } from "../../../../../components/Pagination/interfaces";
import { PriceModal } from "../modals";
// import { courseModal } from "../../../../../redux/reducers/course";
// import { useAppDispatch } from "../../../../../redux/hooks";
// import { MESSAGES } from "../../../../../utils/messages";
import { ROUTE_CONSTANTS } from "../../../../../routes/constants";
// import { INSTRUCTOR } from "../../../../../utils/instructor";
import AddorEdit from "../modals/AddorEdit";
import { universityList } from "../../../../university/api";
import { CourseDummy } from "../../../../../assets/images";
import { IMAGE_BASE_URL } from "../../../../../config";

const CourseList = () => {
  // const dispatch = useAppDispatch();
  const { id } = useParams();

  const [course, setCourse] = useState<CourseDeatails[]>([]);
  const [priceModal, setPriceModal] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const [universities, setUniversities] = useState<any>([]);
  const [universityFilter, setUniversityFilter] = useState<number>(0);
  const [modeFilter, setModeFilter] = useState<number>(0);
 useEffect(() => {
   universityList({}).then((res)=>{
    setUniversities(res.data)

   })
  }, [])

  const navigate = useNavigate();
  // const [showModal, setShowModal] = useState<boolean>(false)
  const [pagination, setPagination] = useState<PaginationInterface>({
    page: 1,
    limit: 10,
    count: 0,
    totalPages: 0,
  });
  // const addNewCourse = () => {
  // 	dispatch(courseModal({ id: 0, editOrAdd: INSTRUCTOR.REDUX.ADD }));
  // 	setShowModal(true);
  // };

  const refresh = () => {
    setLoading(true);
    const query = {
      per_page: pagination.limit,
      page: pagination.page,
    };

    courseList(query, universityFilter,undefined,undefined,undefined,modeFilter,Number(id)).then((res) => {
      const {
        paginate: { total, last_page },
        data,
      } = res;
      setPagination({ ...pagination, count: total, totalPages: last_page });
      setCourse(data);
      setLoading(false);
    });
  };

  // const handleRemoveCourse = (id: number) => {
  // 	toast.promise(removeCourse(id), {
  // 		pending: {
  // 			render() {
  // 				return MESSAGES.COURSE.REMOVE;
  // 			},
  // 		},
  // 		success: {
  // 			render({}) {
  // 				refresh();

  // 				return MESSAGES.COURSE.REMOVED;
  // 			},
  // 		},
  // 		error: {
  // 			render() {
  // 				return MESSAGES.COURSE.NOTREMOVE;
  // 			},
  // 		},
  // 	});
  // };
  const handleClose = () => {
    setPriceModal(false);
  };
  const handleAddClose = () => {
    setShowModal(false);
  };

  // const approveCourse = (id: number) => {
  // 	dispatch(courseModal({ id }));
  // 	setPriceModal(true);
  // };

  useEffect(() => {
    refresh();
  }, [pagination.page,universityFilter,modeFilter]);

  useEffect(() => {
    if (showModal === false) refresh();
  }, [priceModal, showModal]);

  return (
    <Layout>
      <div className={commonStyles["outtre-pad"]}>
        <div className={commonStyles["heaing-filter"]}>
          <h3>Instructor Management/ Courses</h3>
          <div className={commonStyles["filter-left"]}>
          <span>
              University:{" "}
              <select onChange={(e)=>{setUniversityFilter(Number(e.target.value))}}>
                <option value={0}>Select</option>
                {universities.map((res:any)=>{
                  return <option value={res.id}>{res.name}</option>
                })}
              </select>
            </span>
            <span>
              Course Mode:{" "}
              <select onChange={(e)=>{setModeFilter(Number(e.target.value))}}>
              <option value={0}>Select</option>
                <option value={1}>Online</option>
                <option value={2}>In-person</option>
                <option value={3} >Both</option>

              </select>
            </span>
				</div>
        </div>
        {!loading ? (
          <div className={commonStyles["table-data"]}>
            <div className={`${commonStyles["property-head"]} pb-1`}>
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  {/* <button
									className={`nav-link ${commonStyles.activeTab}`}
									id="pills-home-tab"
									data-bs-toggle="pill"
									data-bs-target="#pills-home"
									type="button"
									role="tab"
									aria-controls="pills-home"
									aria-selected="true"
								>
                  All
								</button> */}
                </li>
              </ul>

              {/* <div className={commonStyles["filter-pro"]}>
							<button
								onClick={addNewCourse}
								className={commonStyles["add-lead"]}
							>
								<FontAwesomeIcon icon={faPlus} /> Add New Course
							</button>
						</div> */}
            </div>
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <div>
                  <table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>University</th>
                        <th className="text-center">Category</th>
                        <th>Course Type</th>
                        <th>Online Price </th>
                        <th>In-Person Price</th>

                        <th>online Student Count</th>
                        <th>In-Person Student Count</th>
                        <th>Total Students Count</th>
                        <th>Add-Ons</th>

                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {course.map((item) => {
                        return (
                          <tr key={item.id}>
                            <td className=" bg-light">
                            <img
                              src={!item.image || item.image.endsWith("/storage/") ? CourseDummy : `${IMAGE_BASE_URL}${item.image}`}
                              height={40}
                              style={{borderRadius:"50%"}}
                              width={40}/>
                              {item.name}
                            </td>

                            <td>{item.university.name}</td>
                            <td className=" bg-light">{item.categories?.name}</td>
                            <td>{item.course_type_name}</td>
                            <td className="text-center bg-light ">
                            {item?.course_type !== 2 ? (
                            <>
                              {item?.online_price === null ? (
                              "Not Set"
                              ) : (
                              <>
                                {parseInt(item?.online_price?.toString(), 10) !== parseInt(item?.online_discounted_price?.toString(), 10) ? (
                                <s style={{ marginRight: "5px" }}>{parseInt(item?.online_price?.toString(), 10)}</s>
                                ) : null}
                                {parseInt(item?.online_discounted_price?.toString(), 10)} KD
                              </>
                              )}
                            </>
                            ) : (
                               "Not Applicable"
                            )}
                            </td>
                            <td className="text-center">
                              {item?.course_type !== 1 ? (
                            <>
                              {item?.in_person_price === null ? (
                              "Not Set"
                              ) : (
                              <>
                              {parseInt(item?.in_person_price?.toString(), 10) !== parseInt(item?.in_person_discounted_price?.toString(), 10) ? (
                              <s style={{ marginRight: "5px" }}>{parseInt(item?.in_person_price?.toString(), 10)}</s>
                              ) : null}
                              {parseInt(item?.in_person_discounted_price?.toString(), 10)} KD
                              </>
                              )}
                            </>
                            ) : (
                               "Not Applicable"
                            )}
                            </td>
                            <td className=" bg-light">
                              {item.online_students}
                            </td>
                            <td>
                              {item.offline_students}
                            </td>
                            <td className=" bg-light">
                              {item.purchased_students_count}
                            </td>


                            <td className="text-center">
                            {item?.adson?.map((obj:any) => obj?.name).join(', ')}
                          </td>

                            <td className="bg-light text-center">
                              <Dropdown
                                drop={"down-centered"}
                                className={commonStyles.tddropdown}
                              >
                                <Dropdown.Toggle>
                                  <a className="me-2"></a>
                                  <FontAwesomeIcon icon={faEllipsisH} />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    onClick={() =>
                                      // console.log(`/instructors${ROUTE_CONSTANTS.COURSES}/chapter/${item.id}`)

                                      navigate(
                                        `${ROUTE_CONSTANTS.INSTRUCTORS_CHAPTERS}/${item.id}`
                                      )
                                    }
                                  >
                                    <FontAwesomeIcon
                                      size="xs"
                                      className={commonStyles.iconmargin}
                                      icon={faInfoCircle}
                                    />
                                    View More
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() =>
                                      // console.log(`/instructors${ROUTE_CONSTANTS.COURSES}/chapter/${item.id}`)

                                      navigate(
                                        `${ROUTE_CONSTANTS.INSTRUCTORS_COURSES_STUDENTLIST}/${item.id}`
                                      )
                                    }
                                  >
                                    <FontAwesomeIcon
                                      size="xs"
                                      className={commonStyles.iconmargin}
                                      icon={faInfoCircle}
                                    />
                                    Students List
                                  </Dropdown.Item>
                                  {/* <Dropdown.Item
																onClick={() => {
																	dispatch(
																		courseModal({
																			id: item.id,
																			editOrAdd: INSTRUCTOR.REDUX.EDIT,
																		})
																	);
																	setShowModal(true);
																}}
															>
																<FontAwesomeIcon
																	onClick={() => {
																		dispatch(
																			courseModal({
																				id: item.id,
																				editOrAdd: INSTRUCTOR.REDUX.EDIT,
																			})
																		);
																		setShowModal(true);
																	}}
																	size="xs"
																	className={commonStyles.iconmargin}
																	icon={faEdit}
																/>
                                Edit
															</Dropdown.Item>

															<Dropdown.Item
																onClick={() => approveCourse(item.id)}
															>
																<FontAwesomeIcon
																	size="xs"
																	className={commonStyles.iconmargin}
																	icon={faCheck}
																/>
                                Approve
															</Dropdown.Item>
															<Dropdown.Item
																onClick={() => handleRemoveCourse(item.id)}
															>
																<FontAwesomeIcon
																	size="xs"
																	className={commonStyles.iconmargin}
																	icon={faTrash}
																/>
                                Remove
															</Dropdown.Item> */}
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" role="status"></Spinner>
          </div>
        )}
      </div>

      <Pagination
        paginationState={pagination}
        setPaginationState={setPagination}
      />
      <PriceModal handleClose={handleClose} showModal={priceModal} />
      <AddorEdit showModal={showModal} handleClose={handleAddClose} />
    </Layout>
  );
};
export default CourseList;
