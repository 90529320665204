import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Dropdown, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEllipsisH,
  faEye,
  faPen,
  faPencil,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

import Layout from "../../../../components/Layout/index";
import commonStyles from "../../../common/styles/common.module.css";
import { getStory, removeStory } from "../../api";
import Pagination from "../../../../components/Pagination";
import { PaginationInterface } from "../../../../components/Pagination/interfaces";
import { AddOrEditDepartment } from "../../modals";
import { INSTRUCTOR } from "../../../../utils/instructor";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { departmentModal } from "../../../../redux/reducers/department";
import { MESSAGES } from "../../../../utils/messages";
import { Department as DepartmentInterface, Story } from "../../api/interface";
import { studentList } from "../../../student/api";
import { Student } from "../../../student/api/interface";
import AddOrEditStory from "../../modals/AddOrEditStory";
import DisplayStory from "../../modals/DisplayStory";
import DeleteSegment from "../../modals/DeleteSegment";
import { segmentationModal } from "../../../../redux/reducers/segmentation";
import { useDispatch } from "react-redux";
import EditStory from "../../modals/EditStory";
import ConfirmationModal from "../../../../components/common/ConfirmationModal";

const UniversityStory = () => {
  const { id } = useParams();


  const navigate = useNavigate();

  const [story, setStory] = useState<any>([]);
  const [image, setImage] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [thumbnail, setThumbnail] = useState<string>("");

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [storyToDelete, setCourseToDelete] = useState<number | null>(null);

  const openDeleteModal = (id: number) => {
    setCourseToDelete(id);
    setShowDeleteModal(true);
  };

  const confirmDelete = () => {
    if (storyToDelete !== null) {
      toast.promise(removeStory({id:storyToDelete as number}), {
        pending: MESSAGES.STORY.REMOVING,
        success: {
          render() {
            refresh();
            return MESSAGES.STORY.REMOVED;
          },
        },
        error: MESSAGES.STORY.NOTREMOVED,
      });
    }
    setShowDeleteModal(false);
    setCourseToDelete(null);
  };

  const setBanner = (file: string, title: string, thumbnail:string) => {
    setImage(file);
    setTitle(title);
    setThumbnail(thumbnail);
  };
  // const [story, setStory] = useState<string>("");
  const [pagination, setPagination] = useState<PaginationInterface>({
    page: 1,
    limit: 10,
    count: 0,
    totalPages: 0,
  });
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalDisplay, setShowModalDisplay] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(true);

  const selectedDepartment = useAppSelector((state) => state.departmentReducer);

  const refresh = () => {
    setLoading(true);
    const query = {
      per_page: pagination.limit,
      page: pagination.page,
    };

    getStory(query, Number(id)).then((res) => {
      const {
        paginate: { total, last_page },
        data,
      } = res;
      setPagination({ ...pagination, count: total, totalPages: last_page });
      setStory(data);
      console.log(data);
      setLoading(false);
    });
  };
  useEffect(() => {
    refresh();
  }, []);
  useEffect(() => {
    refresh();
  }, [pagination.page]);

  const handleClose = () => {
    setShowModal(false);
    if (selectedDepartment.editOrAdd === INSTRUCTOR.REDUX.ADD) {
      if (pagination.page === 1) {
        refresh();
      } else {
        setPagination({ page: 1, limit: 10, count: 0, totalPages: 0 });
      }
    } else {
      refresh();
    }
  };
  const handleEditClose = () => {
    setShowEditModal(false);
    if (selectedDepartment.editOrAdd === INSTRUCTOR.REDUX.ADD) {
      if (pagination.page === 1) {
        refresh();
      } else {
        setPagination({ page: 1, limit: 10, count: 0, totalPages: 0 });
      }
    } else {
      refresh();
    }
  };
  const handleCloseDisplay = () => {
    setShowModalDisplay(false);

    refresh();
  };


  const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  const dispatch=useDispatch()

  const handleRemoveSegment = (id: Number) => {
      dispatch(
        segmentationModal({
          id: id,
          editOrAdd: INSTRUCTOR.REDUX.EDIT,
        })
      );
      setShowRemoveModal(true);
    };
    const handleEditStory = (id: Number) => {
      dispatch(
        segmentationModal({
          id: id,
          editOrAdd: INSTRUCTOR.REDUX.EDIT,
        })
      );
      setShowEditModal(true);
    };
    const handleRemoveClose = () => {
      setShowRemoveModal(false);
      if (pagination.page === 1) {
        refresh();
      } else {
        setPagination({ page: 1, limit: 10, count: 0, totalPages: 0 });
      }
    };

  return (
    <Layout>
      <div className={commonStyles["outtre-pad"]}>
        <>
          <span className="instr_manager d-flex justify-content-between align-items-center">
            <h3>University Management / Story</h3>

            <div className={commonStyles["heaing-filter"]}>
            </div>
          </span>
        </>
        <div className={commonStyles["heaing-filter"]}>
          <h3></h3>
        </div>
        {!loading ? (
          <div className={commonStyles["table-data"]}>
            <div className={`${commonStyles["property-head"]} pb-1`}>
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                </li>
              </ul>
              <div className={commonStyles["filter-pro"]}>
                <button
                  onClick={() => {
                    setShowModal(true);
                  }}
                  className={commonStyles["add-lead"]}
                >
                  <FontAwesomeIcon icon={faPlus} /> Add New Story
                </button>
              </div>
            </div>
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <div>
                  <table>
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {story?.map((item:any) => (
                        <tr key={item.id}>
                          <td>{item.title}</td>

                          <td>
                            <Dropdown
                            onClick={() => setBanner(item.files[item.files.length - 1].file, item.title, item.thumbnail)}
                              drop={"down-centered"}
                              className={commonStyles.tddropdown}
                            >
                              <Dropdown.Toggle>
                                <FontAwesomeIcon

                                  icon={faEllipsisH}
                                />
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() => setShowModalDisplay(true)}
                                >
                                  <FontAwesomeIcon
                                    className={commonStyles.iconmargin}
                                    size="xs"
                                    icon={faEye}
                                  />
                                  View Story
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() =>handleEditStory(item.id)}
                                >
                                  <FontAwesomeIcon
                                    className={commonStyles.iconmargin}
                                    size="xs"
                                    icon={faPencil}
                                  />
                                  Edit Story
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() =>openDeleteModal(item.id)}
                                >
                                  <FontAwesomeIcon
                                    className={commonStyles.iconmargin}
                                    size="xs"
                                    icon={faTrash}
                                  />
                                  Remove Story
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" role="status"></Spinner>
          </div>
        )}
      </div>

      <Pagination
        paginationState={pagination}
        setPaginationState={setPagination}
      />
      <AddOrEditStory showModal={showModal} handleClose={handleClose} />
      <EditStory showModal={showEditModal} handleClose={handleEditClose} />

      <DisplayStory
        link={image}
        title={title}
        thumbnail={thumbnail}
        showModal={showModalDisplay}
        handleClose={handleCloseDisplay}
      />
      <ConfirmationModal
        show={showDeleteModal}
        title="Delete Story"
        message="Are you sure you want to delete this Story? This action cannot be undone."
        onConfirm={confirmDelete}
        onCancel={() => setShowDeleteModal(false)}
        confirmButtonText="Delete"
        cancelButtonText="Cancel"
      />
    </Layout>
  );
};

export default UniversityStory;
