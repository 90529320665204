export const STUDENT = {
	FORMIK: {
		USERTYPE: "userType",
		TRAININGFIELD: "trainingField",
		PAYMENTLINK:"paymentLink",
		AMOUNT:"amount",
		CHAPTER:"chapter",
		COURSE:"course",
		DEVICERESTRICTION:"deviceRestriction",
		WALLET: "wallet",
		DATE:"date"

	},

	STUDENTTYPE: {
		SCHOOL: "school",
		UNIVERSITY: "university",
		GENERAL: "general",
	},
	SCHOOL: "School",
	UNIVERSITY: "University",
	GENERAL: "General",
	ACTIVATE:"Activate",
	DEACTIVATE:"Deactivate"
}