import React, { useEffect, useState } from "react";
// import { useFormik } from "formik";
// import { toast } from "react-toastify";
import { Button, Form, Modal } from "react-bootstrap";

import { AddUniversityProp } from "./interface";
// import { useAppSelector } from "../../../redux/hooks";
// import { INSTRUCTOR } from "../../../utils/instructor";
import styles from "../../instructor/styles/instructor.module.css";
 import commonstyles from "../../instructor/styles/common.module.css";
// import { addUniversity, editUniversity, getUniversity } from "../api";
// import DepartmentDetailsSchema from "../validations";
import { UNIVERSITY } from "../../../utils/university";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { addSemester, getInstallment, getSpecificSemesters, updateSpecificSemesters } from "../api";
import { SemesterSchema } from "../validations";
import { useAppSelector } from "../../../redux/hooks";
import Select from 'react-select'
import { courseList, getSpecificAddons } from "../../course/api";
// import { MESSAGES } from "../../../utils/messages";

const EditSemester = ({ showModal, handleClose }: AddUniversityProp) => {
	const {id}=useParams()
	// const selectedUniversity = useAppSelector((state) => state.universityReducer);
	const selectedSegment = useAppSelector((state) => state.segmentationReducer.id);
	const [courseOption,setCourseOption]=useState<any[]>([])
	const [selectedCourseOption,setSelectedCourseOption]=useState<any[]>([])
	const [disableButton, setDisableButton] = useState<boolean>(false);


	const [planOption,setPlanOption]=useState<any[]>([])
	const [selectedplanOption,setSelectedplanOption]=useState<any[]>([])
	const handleCourseChange = (selectedOption:any) => {
		setSelectedCourseOption(selectedOption);
		console.log(selectedOption,"reee")
	  };
	  const handlePlanChange = (selectedOption:any) => {
		setSelectedplanOption(selectedOption);
		console.log(selectedOption,"rddsdeee")

	  };
	  const selectAllPlans=(e:any)=>{
		if(e.target.checked)
		{
			setSelectedplanOption(planOption)
		}
		else{
			setSelectedplanOption([])

		}
	}
	const selectAllCourse=(e:any)=>{
		if(e.target.checked)
		{
			setSelectedCourseOption(courseOption)
		}
		else{
			setSelectedCourseOption([])

		}
	}

	  useEffect(()=>{
		courseList(undefined,Number(id)).then((res)=>{
			const  coursesOption=res.data.map((course)=>{
				return {value:course.id,label:course.name}

			})
			setCourseOption(coursesOption)
		})

		getInstallment({university_id:id}).then((res)=>{
			const planOptions=res.data.map((plan:any)=>{
				return {value:plan.id,label:`Title: ${plan.title}, Installment Count: ${plan.installment}, Down Payment: ${plan.down_payment}%`,date:plan.data,title:plan.title}
			})
			setPlanOption(planOptions)
		})


	},[showModal])

	useEffect(() => {
		if(selectedSegment!==0&&showModal===true){getSpecificSemesters(Number(id),Number(selectedSegment)).then((res)=>{
		semesterDetailsFormik.setValues(res.data)
		const courses=res.data?.courses?.map((cour:any)=>{
			return {value:cour.course_id,label:cour.course.name}
		})
		const plans=res.data?.semesterInstallmentPlans?.map((cour:any)=>{
			return {value:cour.installment_plan_id,label:`Title: ${cour.installment_plan.title}, Installment Count: ${cour.installment_plan.installment}, Down Payment: ${cour.installment_plan.down_payment}%`,date:cour.data,title:cour.title}
		})
		console.log(courses,"testtt")
		setSelectedCourseOption(courses)
		setSelectedplanOption(plans)
		})}

	}, [showModal]);

	const semesterDetailsFormik = useFormik({
		initialValues: {
			name:"",
			start_date: "",
			end_date:""
		},
		enableReinitialize: true,
		onSubmit: (values) => {
			const plan=selectedplanOption.map((pl)=>{
				return pl.value
			})
			const course=selectedCourseOption.map((pl)=>{
				return pl.value
			})
			let failed=false;
			selectedplanOption.map((plan)=>{
				console.log(plan)
				if (plan.date) {
					plan.date.map((pd:any)=>{
						if(Date.parse(pd.date)<Date.parse(values.start_date)||Date.parse(pd.date)>Date.parse(values.end_date))
						{
							toast.error(`${plan.title} Plan is not valid`);
							failed=true
							return;
						}
					})
				}
				if(failed)
				{
					return
				}
			})
			if(!failed){toast.promise(
				updateSpecificSemesters(Number(selectedSegment),{...values,installment_plan_id:plan,course_ids:course}),
				// selectedUniversity.editOrAdd === INSTRUCTOR.REDUX.ADD
				// 	? addUniversity(values.name)
				// 	: editUniversity(selectedUniversity.id, values.name),
				{
					pending: {
						render() {
							setDisableButton(true);
							return "Editing"
							// return selectedUniversity.editOrAdd === INSTRUCTOR.REDUX.ADD
							// 	? MESSAGES.UNIVERSITY.ADD
							// 	: MESSAGES.UNIVERSITY.EDIT;
						},
					},
					success: {
						render() {
							setDisableButton(false);
							semesterDetailsFormik.resetForm();
							handleClose();
							return "Edited"

							// return selectedUniversity.editOrAdd === INSTRUCTOR.REDUX.ADD
							// 	? MESSAGES.UNIVERSITY.SUCCESS
							// 	: MESSAGES.UNIVERSITY.EDITED;
						},
					},
					error: {
						render({data}:any) {
							setDisableButton(false);
							return data.data.message || "Failed"
							// return selectedUniversity.editOrAdd === INSTRUCTOR.REDUX.ADD
							// 	? MESSAGES.UNIVERSITY.INVALID
							// 	: MESSAGES.UNIVERSITY.NOTEDITED;
						},
					},
				}
			);}
		},

	 validationSchema: SemesterSchema,
	});
	// const handleAddDepartment = (e: React.SyntheticEvent<HTMLFormElement>) => {
	// 	e.preventDefault();

	// 	semesterDetailsFormik.submitForm();
	// };

	return (
		<Modal
			show={showModal}
			onHide={() => {
				handleClose();
			}}
		>
			<div>
				<Modal.Header closeButton>
					<Modal.Title>
						{/* {selectedUniversity.editOrAdd === INSTRUCTOR.REDUX.EDIT
							? "Edit University"
							: "Add New University"} */}
                            Edit Semester
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form >
						<div className={styles.fieldStyle}>
						<Form.Group className="from-group" controlId="formFirstName">
								<Form.Label>Name</Form.Label>
								<Form.Control
									type="text"
									placeholder="Name"
									value={semesterDetailsFormik.values.name}
									name={"name"}
									onChange={semesterDetailsFormik.handleChange}


								/>
							</Form.Group>
							{semesterDetailsFormik.errors.name &&
              semesterDetailsFormik.touched.name ? (
									<span className={commonstyles.error}>
										{semesterDetailsFormik.errors.name}
									</span>
								) : null}
							<Form.Group className="from-group" controlId="formFirstName">
								<Form.Label>Start Date</Form.Label>
								<Form.Control
									type="date"
									placeholder="Name"
									value={semesterDetailsFormik.values.start_date}
									name={"start_date"}
									onChange={semesterDetailsFormik.handleChange}


								/>
							</Form.Group>
							{semesterDetailsFormik.errors.start_date &&
              semesterDetailsFormik.touched.start_date ? (
									<span className={commonstyles.error}>
										{semesterDetailsFormik.errors.start_date}
									</span>
								) : null}
							<Form.Group className="from-group" controlId="formFirstName">
								<Form.Label>End Date</Form.Label>
								<Form.Control
									type="date"
									value={semesterDetailsFormik.values.end_date}
									onChange={semesterDetailsFormik.handleChange}
									name="end_date"

								/>
							</Form.Group>
							{semesterDetailsFormik.errors.end_date &&
              semesterDetailsFormik.touched.end_date ? (
									<span className={commonstyles.error}>
										{semesterDetailsFormik.errors.end_date}
									</span>
								) : null}
						</div>
						<Form.Group className="from-group" controlId="formFirstName">
						<Form.Label style={{display:"flex",justifyContent:"space-between"}}>Courses
								<div style={{display:"flex",justifyContent:"space-between"}}><Form.Check  onChange={selectAllCourse} style={{marginRight:"10px"}}/>
								Select All</div>

								</Form.Label>

								<div>
								<Select onChange={handleCourseChange}  value={selectedCourseOption} styles={{
									control:(provided,state)=>({
									...provided,
									width:"470px"
								}),
								option: (provided, state) => ({
									...provided,
									width:"470px"
								  }),
								  menu: (provided, state) => ({
									...provided,
									width:"470px"
								  }),
								}} placeholder={"Select Courses"} isMulti={true} options={courseOption}/>
								</div></Form.Group>
								<Form.Group className="from-group" controlId="formFirstName">
								<Form.Label style={{display:"flex",justifyContent:"space-between"}}>Instalment Plans
								<div style={{display:"flex",justifyContent:"space-between"}}><Form.Check onChange={selectAllPlans} style={{marginRight:"10px"}}/>
								Select All</div>

								</Form.Label>


								<div><Select
								onChange={handlePlanChange}
								styles={{
									control:(provided,state)=>({
									...provided,
									width:"470px"
								}),
								option: (provided, state) => ({
									...provided,
									width:"470px"
								  }),
								  menu: (provided, state) => ({
									...provided,
									width:"470px"
								  }),
								}} placeholder={"Select Plans"} value={selectedplanOption} isMulti={true} options={planOption}/>
								</div></Form.Group>
						<Button onClick={()=>semesterDetailsFormik.submitForm()} disabled={disableButton} className={`${styles.buttonss} app-primary-btn mt-3`} >
                Edit
						</Button>
					</Form>
				</Modal.Body>
			</div>
		</Modal>
	);
};

export default EditSemester;
