import * as Yup from "yup";

import { STUDENT } from "../../../utils/student"
import commonSchema from "../../common/validations";
import { YUP } from "../../../utils/validations";

const StudentDetailsSchema = commonSchema.concat(Yup.object().shape({
    userType: Yup.string(),
    deviceRestriction:Yup.number().notOneOf([0], YUP.REQUIRED).positive().integer().required(YUP.REQUIRED),
    school: Yup
        .number()
        .when(STUDENT.FORMIK.USERTYPE, {
            is: STUDENT.STUDENTTYPE.SCHOOL,
            then: () => Yup.number().notOneOf([0], YUP.REQUIRED),
            otherwise: () => Yup.number().notRequired(),
        }),
    university: Yup
        .number()
        .when(STUDENT.FORMIK.USERTYPE, {
            is: STUDENT.STUDENTTYPE.UNIVERSITY,
            then: () => Yup.number().notOneOf([0], YUP.REQUIRED),
            otherwise: () => Yup.number().notRequired(),
        }),
    department: Yup
        .number()
        .when(STUDENT.FORMIK.USERTYPE, {
            is: STUDENT.STUDENTTYPE.UNIVERSITY,
            then: () => Yup.number().notOneOf([0], YUP.REQUIRED),
            otherwise: () => Yup.number().notRequired(),
        }),
    trainingField: Yup
        .number()
        .when(STUDENT.FORMIK.USERTYPE, {
            is: STUDENT.STUDENTTYPE.GENERAL,
            then: () => Yup.number().notOneOf([0], YUP.REQUIRED),
            otherwise: () => Yup.number().notRequired(),
        }),
    password: Yup.string().notRequired(),
}));

const AddCourseSchema=Yup.object().shape({
	paymentLink: Yup.string().url(YUP.LINK),
	course: Yup.number().integer(YUP.REQUIRED).positive(YUP.REQUIRED).required(YUP.REQUIRED),
	subscription_type: Yup.number().integer(YUP.REQUIRED).positive(YUP.REQUIRED).required(YUP.REQUIRED),
	amount: Yup.number().positive(YUP.REQUIRED).required(YUP.REQUIRED).max(3000, YUP.AMOUNTMAX),
	data: Yup.array()
	.when('payment_type', {
		is: 'installment',
		then:()=> Yup.array().min(1, 'Installments Required').required("Installments Required"),
		otherwise:()=> Yup.array().notRequired(), // Not required if payment type is not "installment"
	})
	
})

export { StudentDetailsSchema, AddCourseSchema};