import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Button, Form, Modal } from "react-bootstrap";

import { AddUniversityProp } from "../../../../modals/interface";
// import { useAppSelector } from "../../../redux/hooks";
// import { INSTRUCTOR } from "../../../utils/instructor";
import styles from "../../../../../instructor/styles/instructor.module.css";
 import commonstyles from "../../../../../instructor/styles/instructor.module.css";
// import { addUniversity, editUniversity, getUniversity } from "../api";
// import { DepartmentDetailsSchema, UniversityDetailsSchema } from "../validations";
// import { UNIVERSITY } from "../../../utils/university";
// import { MESSAGES } from "../../../utils/messages";
// import { uploadImage } from "../../common/utils";
// import AddImage from "./UploadImageModal";
import { useParams } from "react-router-dom";
import { courseList, editCourse } from "../../../../../course/api";
import { CourseDetailsSchema } from "../validations";

const AddOrEdit = ({ showModal, handleClose }: AddUniversityProp) => {
const {id,uid}=useParams()
const [courses,setCourses]=useState<any>([])

useEffect(() => {

    courseList(undefined,Number(uid)).then((res)=>{
        setCourses(res.data)
    })

}, [showModal])






  const courseDetailsFormik = useFormik({
    initialValues: {
     course:""
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      const form = new FormData();
      form.append("category_id", String(id));
      form.append("isPublished", "1");
      // form.append("_method", "PUT");
      form.append("course", values.course);

        toast.promise(
          editCourse(form,Number(values.course)),

          {
            pending: {
              render() {
                return "Adding"
              },
            },
            success: {
              render() {
                handleClose();

                return "Added"
              },
            },
            error: {
              render() {
                return "Failed";
              },
            },
          }
        );

    },

     validationSchema: CourseDetailsSchema,
  });
  const handleAddDepartment = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    courseDetailsFormik.submitForm();
  };

  return (
    <Modal
      show={showModal}
      onHide={() => {
        handleClose();
      }}
    >
      <div>
        <Modal.Header closeButton>
          <Modal.Title>
            {"Add Course to Category"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleAddDepartment}>

          <div className={styles.fieldStyle}>

<Form.Group className="from-group" controlId="formUniversity">
    <Form.Label>Course Type</Form.Label>
    <Form.Control

        name={"course"}
        value={Number(courseDetailsFormik.values.course)}
        as="select"
        onChange={courseDetailsFormik.handleChange}
    >
        <option value={"0"} key={1}>
Select Course
        </option>
        {courses.map((course:any)=>{
            return <option value={course.id} key={2}>
           {course.name}
                    </option>
        })}



    </Form.Control>
</Form.Group>
{courseDetailsFormik.errors.course &&
              courseDetailsFormik.touched.course ? (
										<span className={commonstyles.error}>
											{courseDetailsFormik.errors.course}
										</span>
									) : null}
</div>

            <Button className={`${styles.buttonss} app-primary-btn mt-3`} type="submit">
             Add
            </Button>
          </Form>
        </Modal.Body>

      </div>
    </Modal>
  );
};

export default AddOrEdit;
