import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { Dropdown, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEllipsisH } from "@fortawesome/free-solid-svg-icons";

import Layout from "../../../../../../components/Layout/index";
import commonStyles from "../../../../../common/styles/common.module.css";
import { getChapter } from "../../api";
import { ChapterInfo } from "../../api/interface";
import Pagination from "../../../../../../components/Pagination";
import { PaginationInterface } from "../../../../../../components/Pagination/interfaces";
import Edit from "../../modals/chapters/Edit";
import { ROUTE_CONSTANTS } from "../../../../../../routes/constants";

const Chapter = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [chapter, setChapter] = useState<ChapterInfo[]>([]);
  const [description, setDescription] = useState<String>();
  const [pagination, setPagination] = useState<PaginationInterface>({
    page: 1,
    limit: 10,
    count: 0,
    totalPages: 0,
  });
  const [loading, setLoading] = useState<boolean>(true);

  const handleClose = () => {
    setShowModal(false);
  };

  const refresh = () => {
    setLoading(true);
    const query = {
      per_page: pagination.limit,
      page: pagination.page,
    };

    getChapter(id as string, query).then((res) => {
      const {
        paginate: { total, last_page },
        data,
      } = res;
      setPagination({ ...pagination, count: total, totalPages: last_page });
      setDescription(data[0]?.courses?.description);
      setChapter(data);
      setLoading(false);
    });
  };
  useEffect(() => {
    refresh();
  }, []);
  useEffect(() => {
    if (!showModal) refresh();
  }, [pagination.page, showModal]);

  return (
    <Layout>
      <div className={commonStyles["outtre-pad"]}>
        <div className={commonStyles["heaing-filter"]}>
          <h3>Chapters</h3>
          {/* <div className={commonStyles["filter-left"]}>
						<span>Filter: <select>
							<option>Location</option>
							<option>This Week</option>
						</select></span>
						<span>Filter: <select>
							<option>Price</option>
							<option>This Week</option>
						</select></span>
						<span>Filter: <select>
							<option>Department</option>
							<option>This Week</option>
						</select></span>
						<span>Filter: <select>
							<option>This Week</option>
							<option>This Week</option>
						</select></span>
					</div> */}
        </div>
        {!loading ? (
          <div className={commonStyles["table-data"]}>
            <div className={`${commonStyles["property-head"]} pb-1`}>
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  {description?.length && (
                    <button
                      className={`nav-link ${commonStyles.activeTab}`}
                      id="pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-home"
                      type="button"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      Description
                    </button>
                  )}
                  {description?.length && (
                    <p className={commonStyles.description}>{description}</p>
                  )}{" "}
                  <button
                    className={`nav-link ${commonStyles.activeTab}`}
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    All
                  </button>
                </li>
              </ul>
              {/* <div className={commonStyles["filter-pro"] }>
							<button onClick={()=>{}}  className={commonStyles["add-lead"] }>
								<FontAwesomeIcon icon={faPlus} /> Add New Course
							</button>
						</div> */}
            </div>
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <div >
                  <table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Price</th>
                        <th className="text-center">Created At</th>
                        <th className="text-center">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {chapter.map((item) => (
                        <tr key={item.id}>
                          <td>{item.name}</td>
                          <td>{item.price} KD</td>
                          <td className="text-center">
                            {moment(item.created_at).format("MMM DD, YYYY")}
                          </td>
                          <td className="text-center">
                            <Dropdown
                              drop={"down-centered"}
                              className={commonStyles.tddropdown}
                            >
                              <Dropdown.Toggle>
                                <a className="me-2"></a>
                                <FontAwesomeIcon icon={faEllipsisH} />
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() => {
                                    navigate(
                                      `${ROUTE_CONSTANTS.INSTRUCTOR_CHAPTERINFO}/${item.id}`
                                    );
                                  }}
                                >
                                  <FontAwesomeIcon
                                    size="xs"
                                    className={commonStyles.iconmargin}
                                    icon={faEdit}
                                  />
                                  View Detail
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" role="status"></Spinner>
          </div>
        )}
      </div>

      <Pagination
        paginationState={pagination}
        setPaginationState={setPagination}
      />
      <Edit showModal={showModal} handleClose={handleClose} />
    </Layout>
  );
};

export default Chapter;
