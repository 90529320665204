import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { MESSAGES } from "../../../utils/messages";
import React, { useEffect, useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { useAppSelector } from "../../../redux/hooks";
import { INSTRUCTOR } from "../../../utils/instructor";
import { DepartmentDetailsSchema } from "../validations";
import { UNIVERSITY } from "../../../utils/university";
import { AddUniversityProp, Department } from "./interface";
import { addCategory, editCategory, getCategory } from "../api";

const AddOrEditCategory = ({ showModal, handleClose }: AddUniversityProp) => {
  const selectedDepartment = useAppSelector((state) => state.departmentReducer);
  const [uploadedImage, setuploadedImage] = useState<File | null>(null);
  const { id } = useParams();

  useEffect(() => {
    if (selectedDepartment.editOrAdd === INSTRUCTOR.REDUX.EDIT) {
      getCategory(Number(selectedDepartment.id)).then((res) => {
        const {
          data: { name, department_type },
        } = res;

        departmentDetailsFormik.setFieldValue(UNIVERSITY.FORMIK.NAME, name);
        departmentDetailsFormik.setFieldValue(
          "department_type",
          department_type
        );
      });
    } else {
      departmentDetailsFormik.resetForm();
    }
  }, [showModal]);

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files && event.currentTarget.files[0]) {
      const file = event.currentTarget.files[0];
      setuploadedImage(file);
      if (file.type.startsWith("image/")) {
        departmentDetailsFormik.setFieldValue("file", file);
      } else {
        toast.error("Please upload a valid image file.");
      }
    }
  };

  const departmentDetailsFormik = useFormik<Department>({
    initialValues: {
      name: "",
      department_type: 1,
      file: null,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("university_id", id as string);

      if (uploadedImage) {
        formData.append("icon", uploadedImage);
      }
      if (selectedDepartment.editOrAdd !== INSTRUCTOR.REDUX.ADD) {
        formData.append("_method", "Put");
      }
      toast.promise(
        selectedDepartment.editOrAdd === INSTRUCTOR.REDUX.ADD
          ? addCategory(formData)
          : editCategory(selectedDepartment.id, formData),
        {
          pending: {
            render() {
              return selectedDepartment.editOrAdd === INSTRUCTOR.REDUX.ADD
                ? MESSAGES.CATEGORY.ADD
                : MESSAGES.CATEGORY.EDIT;
            },
          },
          success: {
            render() {
              departmentDetailsFormik.resetForm();
              setuploadedImage(null);
              handleClose();

              return selectedDepartment.editOrAdd === INSTRUCTOR.REDUX.ADD
                ? MESSAGES.CATEGORY.SUCCESS
                : MESSAGES.CATEGORY.EDITED;
            },
          },
          error: {
            render({ data }: { data: any }) {
              const errorMessage =
                data?.data?.message || MESSAGES.CATEGORY.INVALID;
              return errorMessage;
            },
          },
        }
      );
    },

    validationSchema: DepartmentDetailsSchema,
  });
  const handleAddOrEditCategory = (
    e: React.SyntheticEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    departmentDetailsFormik.submitForm();
  };

  return (
    <Modal
      show={showModal}
      onHide={() => handleClose()}
      centered
      className="custom-modal"
    >
      <div>
        <Modal.Header closeButton>
          <Modal.Title className=" fw-bold">
            {selectedDepartment.editOrAdd === INSTRUCTOR.REDUX.EDIT
              ? "Edit Category"
              : "Add New Category"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleAddOrEditCategory}>
            {/* Category Name */}
            <div className="mb-4">
              <Form.Group controlId="formCategoryName">
                <Form.Label className="fw-semibold">Category Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Category Name"
                  value={departmentDetailsFormik.values.name}
                  name={UNIVERSITY.FORMIK.NAME}
                  onChange={departmentDetailsFormik.handleChange}
                  className="form-control shadow-sm"
                />
                {departmentDetailsFormik.errors.name &&
                departmentDetailsFormik.touched.name ? (
                  <small className="text-danger">
                    {departmentDetailsFormik.errors.name}
                  </small>
                ) : null}
              </Form.Group>
            </div>

            <div className="mb-4">
                <Form.Group controlId="formCategoryLogo">
                <Form.Label className="fw-semibold">Upload Logo</Form.Label>
                <Form.Control
                  type="file"
                  accept=".svg"
                  name="file"
                  onChange={handleImageUpload}
                  className="form-control shadow-sm"
                />
                </Form.Group>
            </div>

            <div className="text-center">
              <Button
                type="submit"
                className="btn btn-primary w-50 shadow-sm fw-bold"
              >
                {selectedDepartment.editOrAdd === INSTRUCTOR.REDUX.ADD
                  ? INSTRUCTOR.ADD
                  : INSTRUCTOR.EDIT}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default AddOrEditCategory;
