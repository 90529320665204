import React, { useEffect, useState } from "react";
import { AddInstructorProp } from "./interface";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { Button, Form, Modal } from "react-bootstrap";

import { addCourse, course, editCourse } from "../api";
import styles from "../../instructor/styles/instructor.module.css";
import commonstyles from "../../instructor/styles/common.module.css";
import { CourseAddDetailsSchema, CourseDetailsSchema } from "../validations";
import { INSTRUCTOR } from "../../../utils/instructor";
import { useAppSelector } from "../../../redux/hooks";
import {
	DepartmentInterface,
	Instructor,
	UniversityInterface,
} from "../../instructor/api/interface";
import {
	getAllInstructorUniversity,
	getAllUniversities,
} from "../../instructor/api";
import { AddCourseParam } from "../api/interface";
import { PaginationInterface } from "../../../components/Pagination/interfaces";
import { COURSES } from "../../../utils/courses";
import { MESSAGES } from "../../../utils/messages";
import { YUP } from "../../../utils/validations";
import { uploadImage } from "../../common/utils";
import { commonDepartmentList, getSemesters } from "../../university/api";
import { on } from "events";
import moment from "moment";

const AddorEdit = ({ showModal, handleClose,university }: any) => {
	const selectedCourse = useAppSelector((state) => state.courseReducer);
	const [instructors, setInstructors] = useState<Instructor[]>([]);
	const [universities, setUniversities] = useState<UniversityInterface[]>([]);
	const [department, setDepartment] = useState<DepartmentInterface[]>();
	const [semester, setSemester] = useState<any[]>();
	const [uploadedImage, setuploadedImage] = useState<File | null>(null);
	const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files && event.currentTarget.files[0]) {
      const file = event.currentTarget.files[0];
      setuploadedImage(file);
      if (file.type.startsWith("image/")) {
        courseDetailsFormik.setFieldValue("file", file);
      } else {
        toast.error("Please upload a valid image file.");
      }
    }
  };

	const [selectedDepartments, setSelectedDepartments] = useState<number[]>([]);
	const [selectedSemesters, setSelectedSemesters] = useState<number[]>([]);


	const [videoFile, setVideoFile] = useState<File|null>(null);
	const [allCategory,setAllCategory]=useState<any>([])


	const handleFileChange = (e:any) => {
		setVideoFile(e.target.files[0]);
	  };

	const [pagination, setPagination] = useState<PaginationInterface>({
		page: 1,
		limit: 10,
		count: 0,
		totalPages: 0,
	});

	const [loading, setLoading]=useState<boolean>(false)

	const addInstructor = (id: number) => {
		getAllInstructorUniversity(id).then((res) => {
			setInstructors(res.data);
			console.log(setPagination);
		});
	};
	useEffect(() => {
		getAllUniversities().then((res) => {
			setUniversities(res?.data);
		});
	}, [showModal]);

	useEffect(() => {
		if (selectedCourse.editOrAdd === INSTRUCTOR.REDUX.EDIT) {
			course(String(selectedCourse.id), pagination).then((res) => {
				console.log(res.data);
				const { name, instructor, university, departments,  whatsapp_link, discount, description, course_type, semesters, code,category_id,online_price,in_person_price,discount_type,  instructor_share,
					instructor_share_type} = res.data;

				console.log("instructor_share_type",instructor_share_type);
				console.log("instructor_share",instructor_share)

				courseDetailsFormik.setFieldValue(COURSES.FORMIK.COURSE, name);
				courseDetailsFormik.setFieldValue("discount", discount)
				courseDetailsFormik.setFieldValue("discount_type", discount_type ?? 2)
				courseDetailsFormik.setFieldValue("share", instructor_share)
				courseDetailsFormik.setFieldValue("shareUnit", instructor_share_type)
				courseDetailsFormik.setFieldValue(COURSES.FORMIK.LINK, whatsapp_link)
				courseDetailsFormik.setFieldValue(COURSES.FORMIK.DESCRIPTION, description)
				courseDetailsFormik.setFieldValue(COURSES.FORMIK.COURSE_TYPE, course_type)
				courseDetailsFormik.setFieldValue(COURSES.FORMIK.SHARE_UNIT, instructor_share_type)
				courseDetailsFormik.setFieldValue(COURSES.FORMIK.SHARE, instructor_share)
				if(Number(instructor_share) > 0)
					{
						courseDetailsFormik.setFieldValue(COURSES.FORMIK.SHOW_INSTRUCTOR_SHARE, true)
					}
				else{
					courseDetailsFormik.setFieldValue(COURSES.FORMIK.SHOW_INSTRUCTOR_SHARE, false)
				}

				if(Number(course_type)===3)
				{
					courseDetailsFormik.setFieldValue("onlinePrice", online_price)
					courseDetailsFormik.setFieldValue("inPersonPrice", in_person_price)





				}
				else if(Number(course_type)===2){

					courseDetailsFormik.setFieldValue(COURSES.FORMIK.PRICE, in_person_price)

				}
				else{
					courseDetailsFormik.setFieldValue(COURSES.FORMIK.PRICE, online_price)

				}

				courseDetailsFormik.setFieldValue("category", category_id)

				courseDetailsFormik.setFieldValue("code", code)


				addInstructor(university.id as number);
				courseDetailsFormik.setFieldValue(
					COURSES.FORMIK.INSTRUCTOR,
					instructor.id
				);
				courseDetailsFormik.setFieldValue(
					COURSES.FORMIK.UNIVERSITY,
					university.id
				);
				addDepartments(Number(university.id));
				addSemesters(Number(university.id))
				const depList = departments.map((dep) => dep.department_id);
				setSelectedDepartments(depList);
				const semList = semesters.map((sem:any) => sem.semester_id);
				setSelectedSemesters(semList);
			});
		} else {
			courseDetailsFormik.resetForm();
			setDepartment([]);
			setSelectedDepartments([]);
			setSemester([])
		}
	}, [selectedCourse.editOrAdd, showModal]);

	const addDepartments = (id: Number) => {
		if (id === 0) {
			setDepartment([]);
			return;
		}
		const selectedUniversity = universities.filter(
			(uni: UniversityInterface) => {
				return uni.id === id;
			}
		);
		setDepartment(selectedUniversity[0]?.universityDepartments);
	};
	const addSemesters = (id: Number) => {
		if (id === 0) {
			setSemester([]);
			return;
		}
		getSemesters({},id as number).then((res)=>{
			setSemester(res.data);
		})


	};
	const handleUniversityChange = (e: any) => {
		courseDetailsFormik.handleChange(e);
		addDepartments(Number(e.target.value));
		addSemesters(Number(e.target.value))
		setSelectedDepartments([]);
		setSelectedSemesters([]);
		courseDetailsFormik.setFieldValue("instructor",0)

		if (Number(e.target.value) === 0) {
			setInstructors([]);
			return;
		}
		addInstructor(Number(e.target.value));
	};
	useEffect(() => {
		addDepartments(Number(university));
		addSemesters(Number(university))
		if (Number(university) === 0) {
			setInstructors([]);
			return;
		}
		addInstructor(Number(university));
	}, [showModal])


	const handleCheckboxClick = (e: React.ChangeEvent<HTMLInputElement>) => {
		courseDetailsFormik.setFieldError(COURSES.FORMIK.DEPARTMENT, "");
		if (e.target.checked === true) {
			setSelectedDepartments([...selectedDepartments, Number(e.target.value)]);
		} else {
			const selectedDepartmentsCopy = selectedDepartments;
			const selectedDepartmentsList = selectedDepartmentsCopy.filter((num) => {
				return num !== Number(e.target.value);
			});
			setSelectedDepartments(selectedDepartmentsList);
		}
	};

	const handleSemesterCheckboxClick = (id:any) => {
		setSelectedSemesters(Number(id) === Number(selectedSemesters[0]) ? [] : [id]);

	};
	const handleInstructorShareCheckboxClick=(e: React.ChangeEvent<HTMLInputElement>)=>{
		if (e.target.checked === true) {
			courseDetailsFormik.setFieldValue(COURSES.FORMIK.SHOW_INSTRUCTOR_SHARE, true)
		} else {
			courseDetailsFormik.setFieldValue(COURSES.FORMIK.SHOW_INSTRUCTOR_SHARE, false)

		}
	}

	const handleAddCourse = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		courseDetailsFormik.submitForm();
	};


	// const handleImageUpload=(e:any)=>{
	// 	setLoading(true);
	// 	const file=e.target.files[0];
	// 	uploadImage(file).then((res)=>{
	// 		console.log(res);
	// 		courseDetailsFormik.setFieldValue(COURSES.FORMIK.IMAGE, res.data[0])
	// 		setLoading(false)
	// 	})}
useEffect(() => {
	courseDetailsFormik.setFieldValue("departments",selectedDepartments)

}, [selectedDepartments])
useEffect(() => {
	courseDetailsFormik.setFieldValue("semesters",selectedSemesters)

}, [selectedSemesters])

	const courseDetailsFormik = useFormik({
		initialValues: {
			course: "",
			instructor: 0,
			departments: [],
			university: 0,
			description:"",
			link:"",
			price: null,
			share:0,
			shareUnit:1,
			showInstructorShare: false,
			course_type:1,
			image:"",
			code:"",
			onlinePrice: null,
			inPersonPrice: null,
			discount:null,
			discount_type:2,
			intro_video:null,
			category:0,
		},
		enableReinitialize: true,
		onSubmit: (values) => {

			const payload = new FormData();

			// Map the selected departments to the correct format
			const formattedDepartments = values.departments.map((id) => ({
				department_id: id,
			}));

			payload.append("departments", JSON.stringify(formattedDepartments));

			if (values.instructor !== 0 && values.instructor !== null) {
				payload.append("instructor_id", String(values.instructor));
			}
			payload.append("university_id", String(values.university));
			payload.append("name", values.course);
			payload.append("isPublished", "1");
			if (values.discount !== null && values.discount !== "") {
				console.log("values.discount", values.discount);
				payload.append("discount", String(values.discount));
			}
			else{
				payload.append("discount", "");
			}
			payload.append("description", values.description || "");
			payload.append("whatsapp_link", values.link);
			payload.append("course_type", String(values.course_type));
			payload.append("code", values.code);
			payload.append("state_id", "1");
			if (values.category !== 0 && values.category !== null) {
				payload.append("category_id", String(values.category));
			}
			payload.append("discount_type", String(values.discount_type));

			if (values.image) {
				payload.append("image", values.image);
			}

			if (selectedCourse.editOrAdd === INSTRUCTOR.REDUX.ADD) {
				payload.append("university_id", String(university));
			}

			if (values.showInstructorShare) {
				payload.append("instructor_share", String(values.share));
				payload.append("instructor_share_type", String(values.shareUnit));
			}
			switch (Number(values.course_type)) {
				case 1: // Online course
					if (values.price !== null) {
						payload.append("online_price", String(values.price));
					}
					break;
				case 2: // In-person course
					if (values.price !== null) {
						payload.append("in_person_price", String(values.price));
					}
					break;
				case 3: // Both online and in-person course
					if (values.onlinePrice !== null) {
						payload.append("online_price", String(values.onlinePrice));
					}
					if (values.inPersonPrice !== null) {
						payload.append("in_person_price", String(values.inPersonPrice));
					}
					break;
				default:
					break;
			}

			if (uploadedImage) {
				payload.append("image", uploadedImage);
			}

			if (videoFile) {
				payload.append("intro_video", videoFile);
			}
			// if(selectedCourse.editOrAdd !== INSTRUCTOR.REDUX.ADD){
			// 	payload.append("_method", "PUT");
			// }

			toast.promise(
				selectedCourse.editOrAdd === INSTRUCTOR.REDUX.ADD
					? addCourse(payload)
					: editCourse(payload, selectedCourse.id as number),
				{
					pending: {
						render() {
							return selectedCourse.editOrAdd === INSTRUCTOR.REDUX.ADD
								? MESSAGES.COURSE.ADD
								: MESSAGES.COURSE.EDIT;
						},
					},
					success: {
						render() {
							courseDetailsFormik.resetForm();
							handleClose();

							return selectedCourse.editOrAdd === INSTRUCTOR.REDUX.ADD
								? MESSAGES.COURSE.ADDED
								: MESSAGES.COURSE.EDITED;
						},
					},
					error: {
						render({ data }: any) {
							return data.data.message || "Failed";
						},
					},
				}
			);
		},
	 validationSchema: selectedCourse.editOrAdd === INSTRUCTOR.REDUX.ADD?CourseAddDetailsSchema: CourseDetailsSchema,
	});
	useEffect(() => {
		if(Number(courseDetailsFormik.values.university)!==0){
		commonDepartmentList({university_id:courseDetailsFormik.values.university}).then((res)=>{
			setAllCategory(res.data)
		})
	}
	else{
		setAllCategory([])
	}
	},[courseDetailsFormik.values.university])

	useEffect(() => {
		if(Number(courseDetailsFormik.values.university)!==0){
			commonDepartmentList({university_id:courseDetailsFormik.values.university}).then((res)=>{
				setAllCategory(res.data)
			})
		}
		if(Number(university)!==0)
		{
			commonDepartmentList({university_id:university}).then((res)=>{
				setAllCategory(res.data)
			})
		}
	}, [showModal])


	return (
		<Modal
			show={showModal}
			onHide={() => {
				handleClose();
			}}
		>
			<div className={styles["add-lead-from"]}>
				<Modal.Header closeButton>
					<Modal.Title onClick={()=>console.log(courseDetailsFormik.errors)}>
						{selectedCourse.editOrAdd === INSTRUCTOR.REDUX.EDIT
							? "Edit Course"
							: "Add New Course"}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form onSubmit={handleAddCourse}>
						<div className={styles.fieldStyle}>
							<Form.Group className="from-group" controlId="formFirstName">
								<Form.Label>course</Form.Label>
								<Form.Control
									disabled={loading}
									type="text"
									placeholder="course"
									value={courseDetailsFormik.values.course}
									name={COURSES.FORMIK.COURSE}
									onChange={courseDetailsFormik.handleChange}
								/>
							</Form.Group>
							{courseDetailsFormik.errors.course &&
              courseDetailsFormik.touched.course ? (
									<span className={commonstyles.error}>
										{courseDetailsFormik.errors.course}
									</span>
								) : null}
						</div>
						<div className={styles.fieldStyle}>
							<Form.Group className="from-group" controlId="formFirstName">
								<Form.Label>code</Form.Label>
								<Form.Control
									disabled={loading}
									type="text"
									placeholder="code"
									value={courseDetailsFormik.values.code}
									name={"code"}
									onChange={courseDetailsFormik.handleChange}
								/>
							</Form.Group>
							{courseDetailsFormik.errors.code &&
              courseDetailsFormik.touched.code ? (
									<span className={commonstyles.error}>
										{courseDetailsFormik.errors.code}
									</span>
								) : null}
						</div>
						<div className={styles.fieldStyle}>
							<Form.Group className="from-group" controlId="formFirstName">
								<Form.Label>Discount</Form.Label>
								<Form.Control
									disabled={loading}

									type="number"
									placeholder="Discount"
									value={courseDetailsFormik.values.discount ?? ""}
									name={COURSES.FORMIK.DISCOUNT}
									onChange={courseDetailsFormik.handleChange}
								/>
							</Form.Group>
							{courseDetailsFormik.errors.discount &&
              courseDetailsFormik.touched.discount ? (
									<span className={commonstyles.error}>
										{courseDetailsFormik.errors.discount}
									</span>
								) : null}
						</div>
						<div className={styles.fieldStyle}>

							<Form.Group className="from-group" controlId="formUniversity">
								<Form.Label>Discount Unit</Form.Label>
								<Form.Control
									disabled={loading}

									name={"discount_type"}
									value={Number(courseDetailsFormik.values.discount_type)}
									as="select"
									onChange={courseDetailsFormik.handleChange}
								>
									<option value={2} key={1}>
                    %
									</option>
									<option value={1} key={2}>
                    KD
									</option>

								</Form.Control>
							</Form.Group>

						</div>
						<div className={styles.fieldStyle}>
							<Form.Group className="from-group" controlId="formFirstName">
								<Form.Label>Description( 123 characters maximum)</Form.Label>
								<Form.Control
									as="textarea" rows={5}
									disabled={loading}
									type="text"
									placeholder="Description"
									value={courseDetailsFormik.values.description}
									name={COURSES.FORMIK.DESCRIPTION}
									onChange={courseDetailsFormik.handleChange}
								/>
							</Form.Group>
							{courseDetailsFormik.errors.description &&
              courseDetailsFormik.touched.description ? (
									<span className={commonstyles.error}>
										{courseDetailsFormik.errors.description}
									</span>
								) : null}
						</div>
						<div className={styles.fieldStyle}>
							<Form.Group className="from-group" controlId="formFirstName">
								<Form.Label>Banner Image</Form.Label>
								<Form.Control
	                         accept="image/*"
									type="file"
									name={COURSES.FORMIK.IMAGE}
									disabled={loading}
									onChange={handleImageUpload}
									placeholder="No. of members"
								/>

							</Form.Group>
							{courseDetailsFormik.errors.image &&
              courseDetailsFormik.touched.image ? (
									<span className={commonstyles.error}>
										{courseDetailsFormik.errors.image}
									</span>
								) : null}
						</div>
						<div className={styles.fieldStyle}>
							<Form.Group className="from-group" controlId="formFirstName">
								<Form.Label>Intro Video</Form.Label>
								<Form.Control
	                         accept="video/*"
									type="file"
									name={"intro_video"}
									disabled={loading}
									onChange={handleFileChange}
									placeholder="No. of members"
								/>

							</Form.Group>
							{courseDetailsFormik.errors.image &&
              courseDetailsFormik.touched.image ? (
									<span className={commonstyles.error}>
										{courseDetailsFormik.errors.image}
									</span>
								) : null}
						</div>
						<div className={styles.fieldStyle}>

							<Form.Group className="from-group" controlId="formUniversity">
								<Form.Label>Course Type</Form.Label>
								<Form.Control
									disabled={loading}

									name={COURSES.FORMIK.COURSE_TYPE}
									value={Number(courseDetailsFormik.values.course_type)}
									as="select"
									onChange={courseDetailsFormik.handleChange}
								>
									<option value={1} key={1}>
                    Online
									</option>
									<option value={2} key={2}>
                    In person
									</option>
									<option value={3} key={3}>
                    Both
									</option>

								</Form.Control>
							</Form.Group>

						</div>


						{Number(courseDetailsFormik.values.course_type)===3?<>
							<div className={styles.fieldStyle}>
								<Form.Group className="from-group" controlId="formFirstName">
									<Form.Label>online Price</Form.Label>
									<Form.Control
										disabled={loading}

										type="number"
										placeholder="Online Price"
										value={courseDetailsFormik.values.onlinePrice ?? ''}
										name={COURSES.FORMIK.ONLINE_PRICE}
										onChange={courseDetailsFormik.handleChange}
									/>
								</Form.Group>
								{courseDetailsFormik.errors.onlinePrice &&
              courseDetailsFormik.touched.onlinePrice ? (
										<span className={commonstyles.error}>
											{courseDetailsFormik.errors.onlinePrice}
										</span>
									) : null}
							</div>
							<div className={styles.fieldStyle}>
								<Form.Group className="from-group" controlId="formFirstName">
									<Form.Label>In Person Price</Form.Label>
									<Form.Control
										disabled={loading}

										type="number"
										placeholder="In Person Price"
										value={courseDetailsFormik.values.inPersonPrice ?? ""}
										name={COURSES.FORMIK.INPERSON_PRICE}
										onChange={courseDetailsFormik.handleChange}
									/>
								</Form.Group>
								{courseDetailsFormik.errors.inPersonPrice &&
              courseDetailsFormik.touched.inPersonPrice ? (
										<span className={commonstyles.error}>
											{courseDetailsFormik.errors.inPersonPrice}
										</span>
									) : null}
							</div>
						</>:<div className={styles.fieldStyle}>
							<Form.Group className="from-group" controlId="formFirstName">
								<Form.Label>Price</Form.Label>
								<Form.Control
									disabled={loading}

									type="number"
									placeholder="Price"
									value={courseDetailsFormik.values.price ?? ""}
									name={COURSES.FORMIK.PRICE}
									onChange={courseDetailsFormik.handleChange}
								/>
							</Form.Group>
							{courseDetailsFormik.errors.price &&
              courseDetailsFormik.touched.price ? (
									<span className={commonstyles.error}>
										{courseDetailsFormik.errors.price}
									</span>
								) : null}
						</div>}
						<div className={styles.fieldStyle}>


						<Form.Check
							key={1}
							className="from-check"
							disabled={loading}
							type="checkbox"
							checked={courseDetailsFormik.values.showInstructorShare} // Bind to Formik's value
							onChange={handleInstructorShareCheckboxClick}
							label={"Add Instructor Share"}
						></Form.Check>



							{courseDetailsFormik.errors.share &&
                courseDetailsFormik.touched.share ? (
									<span className={commonstyles.error}>
										{courseDetailsFormik.errors.share}
									</span>
								) : null}
						</div>


						{courseDetailsFormik.values.showInstructorShare && (
							<>
								<div className={styles.fieldStyle}>
									<Form.Group className="from-group" controlId="formFirstName">
										<Form.Label>Instructor Share</Form.Label>
										<Form.Control
											type="number"
											disabled={loading}
											placeholder="Instructor Share"
											value={courseDetailsFormik.values.share}
											name={COURSES.FORMIK.SHARE}
											onChange={courseDetailsFormik.handleChange}
										/>
									</Form.Group>
									{courseDetailsFormik.errors.share && courseDetailsFormik.touched.share ? (
										<span className={commonstyles.error}>{courseDetailsFormik.errors.share}</span>
									) : null}
								</div>

								<div className={styles.fieldStyle}>
									<Form.Group className="from-group" controlId="formUniversity">
										<Form.Label>Instructor Share Unit</Form.Label>
										<Form.Control
											disabled={loading}
											name={COURSES.FORMIK.SHARE_UNIT}
											value={Number(courseDetailsFormik.values.shareUnit)}
											as="select"
											onChange={courseDetailsFormik.handleChange}
										>
											<option value={1} key={1}>
												%
											</option>
											<option value={2} key={2}>
												KD
											</option>
										</Form.Control>
									</Form.Group>
									{courseDetailsFormik.errors.shareUnit && courseDetailsFormik.touched.shareUnit ? (
										<span className={commonstyles.error}>{courseDetailsFormik.errors.shareUnit}</span>
									) : null}
								</div>
							</>
						)}
						{!(selectedCourse.editOrAdd === INSTRUCTOR.REDUX.ADD)&&<div className={styles.fieldStyle}>

							<Form.Group className="from-group" controlId="formUniversity">
								<Form.Label>University</Form.Label>
								<Form.Control
									disabled={loading}

									name={COURSES.FORMIK.UNIVERSITY}
									value={courseDetailsFormik.values.university}
									as="select"
									onChange={(e) => handleUniversityChange(e)}
								>
									<option value={0} key={0}>
                    Select University
									</option>
									{universities?.map((university, id) => (
										<option value={university.id} key={id}>
											{university.name}
										</option>
									))}
								</Form.Control>
							</Form.Group>
							{courseDetailsFormik.errors.university &&
              courseDetailsFormik.touched.university ? (
									<span className={commonstyles.error}>
										{courseDetailsFormik.errors.university}
									</span>
								) : null}
						</div>}
						<div className={styles.fieldStyle}>
							<Form.Group className="from-group" controlId="formUniversity">
								<Form.Label>Category</Form.Label>
								<Form.Control
									name={"category"}
									value={courseDetailsFormik.values.category}
									as="select"
									onChange={courseDetailsFormik.handleChange}
								>
									<option value={0} key={0}>
                    Select Category
									</option>
									{/* <option value={2} key={1}>
                    Diploma
									</option> */}
									{allCategory.map((cat:any)=>{
										return	<option value={cat.id} key={1}>
                    {cat.name}
									</option>
									})}

								</Form.Control>
							</Form.Group>
							{courseDetailsFormik.errors.category &&
              courseDetailsFormik.touched.category ? (
									<span className={commonstyles.error}>
										{courseDetailsFormik.errors.category}
									</span>
								) : null}
						</div>
						<div className={styles.fieldStyle}>

							<Form.Group className="from-group" controlId="formUniversity">
								<Form.Label>Whatsapp Group Link</Form.Label>
								<Form.Control
									disabled={loading}

									type="text"
									placeholder="Link"
									value={courseDetailsFormik.values.link}
									name={COURSES.FORMIK.LINK}
									onChange={courseDetailsFormik.handleChange}
								/>
							</Form.Group>
							{courseDetailsFormik.errors.link &&
courseDetailsFormik.touched.link ? (
									<span className={commonstyles.error}>
										{courseDetailsFormik.errors.link}
									</span>
								) : null}
						</div>

						<div className={styles.fieldStyle}>
							<Form.Group className="from-group" controlId="formUniversity">
								<Form.Label>Instructor</Form.Label>
								<Form.Control
									name={COURSES.FORMIK.INSTRUCTOR}
									value={courseDetailsFormik.values.instructor}
									as="select"
									disabled={loading}

									onChange={courseDetailsFormik.handleChange}
								>
									<option value={0} key={0}>
                    Select Instructor
									</option>
									{instructors?.map((instructor, id) => (
										<option value={instructor.id} key={id}>
											{instructor.full_name}
										</option>
									))}
								</Form.Control>
							</Form.Group>
							{courseDetailsFormik.errors.instructor &&
              courseDetailsFormik.touched.instructor ? (
									<span className={commonstyles.error}>
										{courseDetailsFormik.errors.instructor}
									</span>
								) : null}
						</div>


						{ (
							<div className={styles.fieldStyle}>
								<Form.Label>Departments</Form.Label>

								{department?.map((res, id) => {
									return (
										<Form.Check
											disabled={loading}

											key={id}
											className="from-check"
											value={res.id as number}
											type="checkbox"
											checked={
												selectedDepartments.includes(res.id as number)
													? true
													: false
											}
											onChange={handleCheckboxClick}
											label={res.name}
										></Form.Check>
									);
								})}

								{courseDetailsFormik.errors.departments &&
                courseDetailsFormik.touched.departments ? (
										<span className={commonstyles.error}>
											{courseDetailsFormik.errors.departments}
										</span>
									) : null}
							</div>
						)}

						<Button className={`${styles.buttonss} app-primary-btn mt-3`} disabled={courseDetailsFormik.isSubmitting} type="submit">
							{selectedCourse.editOrAdd === INSTRUCTOR.REDUX.ADD
								? INSTRUCTOR.ADD
								: INSTRUCTOR.EDIT}
						</Button>
					</Form>
				</Modal.Body>
			</div>
		</Modal>
	);
};

export default AddorEdit;
