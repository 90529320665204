import React, { useEffect, useState } from "react";
import { Dropdown, Spinner } from "react-bootstrap";
import Layout from "../../../../../components/Layout";
// // import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// // import {
// // 	faPlus,
// // 	faEllipsisH,
// // 	faEdit,
// // 	faEye,
// // 	faTrash,

// // } from "@fortawesome/free-solid-svg-icons";
// // import moment from "moment";
// // import { Dropdown, Spinner } from "react-bootstrap";
// // // import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

// // import { list, removeInstructor } from "../api";
// // import Layout from "../../../components/Layout";
import commonStyles from "../../../../common/styles/common.module.css";
// // import { Avatar } from "../../../assets/images";
// // import { Instructor } from "../api/interface";
// // import Pagination from "../../../components/Pagination";
import { PaginationInterface } from "../../../../../components/Pagination/interfaces";
import { instalmentList } from "../api";
import Pagination from "../../../../../components/Pagination";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEllipsisH,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import ChangeStatus from "../modals/ChangeStatus";
import { Installment } from "../api/interface";
import UpdateDate from "../modals/UpdateDate";
import Delete from "../modals/Delete";
import Add from "../modals/Add";
// // // import { MESSAGES } from "../../../utils/messages";
// // import { APP_ROUTES } from "../../../utils/routes";
// // import { ROUTE_CONSTANTS } from "../../../routes/constants";
// // import { MESSAGES } from "../../../utils/messages";
// // import { toast } from "react-toastify";

const list = () => {
  const { userId, orderId } = useParams();

  const [selectedId, setSelectedId] = useState<String>("0");

  const [pagination, setPagination] = useState<PaginationInterface>({
    page: 1,
    limit: 10,
    count: 0,
    totalPages: 0,
  });
  // const navigate = useNavigate();

  //const [instructors, setInstructors] = useState<Instructor[]>([]);
  const [instalments, setInstalments] = useState<Installment[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const [showStatusModal, setShowStatusModal] = useState<boolean>(false);
  const [showDateModal, setShowDateModal] = useState<boolean>(false);
  const [showAddModal, setShowAddModal] = useState<boolean>(false);

  const [showDeleteModal, setDeleteModal] = useState<boolean>(false);

  const handleStatusClose = () => {
    setShowStatusModal(false);
  };
  const handleAddClose = () => {
    setShowAddModal(false);
  };
  const handleDateClose = () => {
    setShowDateModal(false);
  };

  const handleDeleteClose = () => {
    setDeleteModal(false);
  };
  const refresh = () => {
    setLoading(true);
    const query = {
      per_page: pagination.limit,
      page: pagination.page,
    };

    instalmentList(query, Number(userId), Number(orderId)).then((res) => {
      const {
        // paginate: { total, last_page },
        data,
      } = res;
      // setPagination({ ...pagination, count: total, totalPages: last_page });
      setInstalments(data);
      setLoading(false);
    });
  };
  useEffect(() => {
    refresh();
  }, [pagination.page]);
  useEffect(() => {
    if (!showStatusModal) refresh();
  }, [showStatusModal]);
  useEffect(() => {
    if (!showDateModal) refresh();
  }, [showDateModal]);
  useEffect(() => {
    if (!showAddModal) refresh();
  }, [showAddModal]);
  useEffect(() => {
    if (!showDeleteModal) refresh();
  }, [showDeleteModal]);

  return (
    <Layout>
      <div className={commonStyles["outtre-pad"]}>
        <div className={commonStyles["heaing-filter"]}>
          <h3>Installments</h3>
          {/* <div className={commonStyles["filter-left"]}>
						<span>Filter: <select>
							<option>Location</option>
							<option>This Week</option>
						</select></span>
						<span>Filter: <select>
							<option>Price</option>
							<option>This Week</option>
						</select></span>
						<span>Filter: <select>
							<option>Department</option>
							<option>This Week</option>
						</select></span>
						<span>Filter: <select>
							<option>This Week</option>
							<option>This Week</option>
						</select></span>
					</div> */}
        </div>

        {!loading ? (
          <div className={commonStyles["table-data"]}>
            <div className={`${commonStyles["property-head"]} pb-1`}>
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  {/* <button
									className={`nav-link ${commonStyles.activeTab}`}
									id="pills-home-tab"
									data-bs-toggle="pill"
									data-bs-target="#pills-home"
									type="button"
									role="tab"
									aria-controls="pills-home"
									aria-selected="true"
								>
                  All
								</button> */}
                </li>
              </ul>
              <div className={commonStyles["filter-pro"]}>
								<button
                onClick={()=>{
                  setShowAddModal(true);
                }}

									className={commonStyles["add-lead"]}
								>
									<FontAwesomeIcon icon={faPlus} /> Add New Installment
								</button>
							</div>
            </div>
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <div>
                  <table>
                    <thead>
                      <tr>
                        <th>Amount</th>
                        <th>Due Date</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {instalments.map((item) => (
                        <tr key={String(item.id)}>
                          <td>{String(item.amount)} KD</td>

                          <td>
                            {moment(item.due_date).format("MMM DD, YYYY")}
                          </td>
                          <td>{item.paid ? "Paid" : "Unpaid"} </td>
                          {/* <td>
												<span className={commonStyles["bl-shd"]}>
													{"View Courses"}
												</span>
											</td> */}
                          <td>
                            <Dropdown
                              drop={"down-centered"}
                              className={commonStyles.tddropdown}
                            >
                              <Dropdown.Toggle>
                                <FontAwesomeIcon icon={faEllipsisH} />
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() => {
                                    setSelectedId(String(item.id));
                                    setShowStatusModal(true);
                                  }}
                                >
                                  <FontAwesomeIcon
                                    className={commonStyles.iconmargin}
                                    icon={faEdit}
                                    size="xs"
                                  />
                                  Change Status
                                </Dropdown.Item>

                               {!item.paid && <Dropdown.Item
                                  onClick={() => {
                                    setSelectedId(String(item.id));
                                    setShowDateModal(true);
                                  }}
                                >
                                  <FontAwesomeIcon
                                    className={commonStyles.iconmargin}
                                    icon={faEdit}
                                    size="xs"
                                  />
                                  Update Date & Amount
                                </Dropdown.Item>}

                                <Dropdown.Item
                                  onClick={() => {
                                    setSelectedId(String(item.id));
                                    setDeleteModal(true);
                                  }}
                                >
                                  <FontAwesomeIcon
                                    className={commonStyles.iconmargin}
                                    icon={faTrash}
                                    size="xs"
                                  />
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" role="status"></Spinner>
          </div>
        )}
      </div>
      <Pagination
        paginationState={pagination}
        setPaginationState={setPagination}
      />
      <ChangeStatus
        showModal={showStatusModal}
        handleClose={handleStatusClose}
        id={selectedId as string}
      />
      <UpdateDate
        showModal={showDateModal}
        handleClose={handleDateClose}
        id={selectedId as string}
      />
      <Delete
        showModal={showDeleteModal}
        handleClose={handleDeleteClose}
        id={selectedId as string}
      />
       <Add
        showModal={showAddModal}
        handleClose={handleAddClose}
        id={selectedId as string}
      />
    </Layout>
  );
};

export default list;
