import { useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import {  Form, Modal } from "react-bootstrap";

import { AddUniversityProp } from "./interface";
import styles from "./styles/style.module.css";
import commonstyles from "../../instructor/styles/common.module.css";
import { MESSAGES } from "../../../utils/messages";
import { InvoiceDetailsSchema } from "../validations";
import { addInvoice } from "../api";
import { INVOICE } from "../../../utils/invoice";

const AddOrEdit = ({ showModal, handleClose }: AddUniversityProp) => {

	const invoiceDetailsFormik = useFormik({
		initialValues: {
			name: "",
			email:"",
			expiry:"",
			mobile:0,
			amount:0
		},
		enableReinitialize: true,
		onSubmit: (values) => {
			toast.promise(
				 addInvoice(values),
				{
					pending: {
						render() {
							return MESSAGES.INVOICE.ADD;
						},
					},
					success: {
						render() {
							invoiceDetailsFormik.resetForm();
							handleClose();

							return MESSAGES.INVOICE.SUCCESS
						},
					},
					error: {
						render() {
							return MESSAGES.INVOICE.INVALID
						},
					},
				}
			);
		},

		validationSchema: InvoiceDetailsSchema,
	});
	const [inputType, setInputType]=useState<string>("text")

	return (
		<Modal
			show={showModal}
			onHide={() => {
				handleClose();
			}}
		>
			<div>
				<Modal.Header closeButton>
					<Modal.Title>
						{"Add Invoice"}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form onSubmit={invoiceDetailsFormik.handleSubmit}>
						<div className={styles.uploadInvoice}>
							<div className={styles.oterCrency}>
								<input onChange={invoiceDetailsFormik.handleChange}  value={invoiceDetailsFormik.values.amount} name={INVOICE.FORMIK.AMOUNT} type="number" placeholder="0"></input>
								<span>KD</span>

							</div>
							{invoiceDetailsFormik.errors.amount &&
              invoiceDetailsFormik.touched.amount ? (
									<span className={commonstyles.error}>
										{invoiceDetailsFormik.errors.amount}
									</span>
								) : null}

							<div className={styles.formGroup}>
								<input onChange={invoiceDetailsFormik.handleChange} value={invoiceDetailsFormik.values.name} type="text" name={INVOICE.FORMIK.NAME} placeholder="Customer name"/>
								<span><i className="fas fa-user"></i></span>

							</div>
							{invoiceDetailsFormik.errors.name &&
              invoiceDetailsFormik.touched.name ? (
									<span className={commonstyles.error}>
										{invoiceDetailsFormik.errors.name}
									</span>
								) : null}
							<div className={styles.formGroup}>
								<input onChange={invoiceDetailsFormik.handleChange} value={invoiceDetailsFormik.values.email} name={INVOICE.FORMIK.EMAIL} type="email" placeholder="Customer Email"/>
								<span><i className="fas fa-user"></i></span>

							</div>
							{invoiceDetailsFormik.errors.email &&
              invoiceDetailsFormik.touched.email ? (
									<span className={commonstyles.error}>
										{invoiceDetailsFormik.errors.email}
									</span>
								) : null}
							<div className={styles.formGroup}>
								<input onChange={invoiceDetailsFormik.handleChange}  name={INVOICE.FORMIK.MOBILE} type="number" placeholder="Mobile number"/>
								<span><i className="fas fa-phone"></i></span>

							</div>
							{invoiceDetailsFormik.errors.mobile &&
              invoiceDetailsFormik.touched.mobile ? (
									<span className={commonstyles.error}>
										{invoiceDetailsFormik.errors.mobile}
									</span>
								) : null}
							<div className={styles.formGroup}>
								<input
									onChange={invoiceDetailsFormik.handleChange}
									value={invoiceDetailsFormik.values.expiry}
									name={INVOICE.FORMIK.EXPIRY}
									type={inputType}
									placeholder="Expiry Date"
									onFocus={()=>{setInputType("date")}}/>
								<span><i className="fas fa-calendar-alt"></i></span>

							</div>
							{invoiceDetailsFormik.errors.expiry &&
              invoiceDetailsFormik.touched.expiry ? (
									<span className={commonstyles.error}>
										{invoiceDetailsFormik.errors.expiry}
									</span>
								) : null}
							{/* <div className={`${styles.formGroup} ${styles.typeFile}`}>
					<input type="file" />
					<span>Upload File</span>
				</div> */}
							<div className={styles.formGroup}>
								<input  type="submit" value="Create Invoice" />
							</div>
						</div>
					</Form>
				</Modal.Body>
			</div>
		</Modal>
	);
};

export default AddOrEdit;
